import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { cloneDeep, set } from "lodash";
import { INITIAL_LIMIT, INITIAL_SORT_FILTER } from "pages/apps/records";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const Filters = styled.div`
  overflow: auto;
  height: 200px;
`;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 100px auto;
  align-items: center;
  gap: 10px;
  margin-bottom: 4px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const TopRightBtn = styled(ButtonWord)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const SortAndFilterConfigModal = ({
  columns = [],
  sortAndFilterConfig = {},
  onChangeConfig = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(sortAndFilterConfig);

  const { sortColumn, direction } = config?.sort || {};

  useEffect(() => {
    setConfig(sortAndFilterConfig);
  }, [JSON.stringify(sortAndFilterConfig)]);

  const updateConfig = (key, value) => {
    const newConfig = cloneDeep(config);
    set(newConfig, key, value);
    setConfig(newConfig);

    return newConfig;
  };

  const onChangeFilterValue = (dbName, newValue) => {
    const newConfig = cloneDeep(config);
    const matchedFilterIndex = newConfig?.filters?.findIndex(
      filter => filter?.dbName === dbName
    );
    if (matchedFilterIndex === -1) {
      newConfig.filters.push({ dbName, likeValues: [newValue] });
    } else {
      newConfig.filters[matchedFilterIndex].likeValues = [newValue];
    }
    setConfig(newConfig);
  };

  const onClickApply = () => {
    const newConfig = updateConfig("sort.limit", INITIAL_LIMIT);
    onChangeConfig(newConfig);
    setIsOpen(false);
  };

  const onClickReset = () => {
    setConfig(INITIAL_SORT_FILTER);
  };

  return (
    <>
      <ButtonWord onClick={() => setIsOpen(true)}>Sort & filter</ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <TopRightBtn onClick={onClickReset}>Reset</TopRightBtn>
          <Title>Sort</Title>
          <Gap height="10px" />
          <div>
            Sort by:{" "}
            <select
              value={sortColumn}
              onChange={e => updateConfig("sort.sortColumn", e.target.value)}
            >
              <option value="">-- Choose column --</option>
              {columns.map(column => (
                <option key={column?.dbName} value={column?.dbName}>
                  {column?.displayName}
                </option>
              ))}
            </select>
          </div>
          <div>
            Direction:{" "}
            <select
              value={direction}
              onChange={e => updateConfig("sort.direction", e.target.value)}
            >
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </select>
          </div>
          <Gap height="40px" />
          <Title>Filters</Title>
          <Gap height="10px" />
          <Filters>
            {columns?.map((column, index) => {
              const matchedFilter = config?.filters?.find(
                filter => filter?.dbName === column?.dbName
              );
              const value = matchedFilter?.likeValues?.[0] || "";

              return (
                <FilterContainer key={index}>
                  {column?.displayName}
                  <input
                    value={value}
                    onChange={e =>
                      onChangeFilterValue(column?.dbName, e.target.value)
                    }
                  />
                </FilterContainer>
              );
            })}
          </Filters>
          <BottomContainer>
            <ButtonWord isPrimary onClick={onClickApply}>
              Apply
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SortAndFilterConfigModal;
