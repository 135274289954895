import styled from "styled-components";
import { useState } from "react";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import DirectoryItemsList from "components/DirectoryItemsList";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import useSearchParamsState from "hooks/useSearchParamsState";
import InputWithBottomSuggestions from "components/InputWithBottomSuggestions";
import { getUserProfileByUserName } from "api/services/projectService";
import {
  getLoggedInUserName,
  isInvestorUser,
} from "api/services/authenticationService";
import {
  AnimatedLogo,
  BoltzbitLogo,
  BoltzhubLogoInner,
  FilesIcon,
  LogoInner,
  PaperClipIcon,
  PlusIcon,
  SendIcon,
} from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";
import ButtonIcon, { ButtonIconTransparent } from "components/ui/ButtonIcon";
import TooltipClick from "components/ui/TooltipClick";
import SourceFilesList from "components/SourceFilesList";
import { parseJson, sleep } from "utils/common";
import SourceFilesListHorizontal from "components/SourceFilesListHorizontal";
import InputWithBottomSuggestionsAndSources from "components/InputWithBottomSuggestionsAndSources";
import { isNil, transform } from "lodash";
import Typewriter from "typewriter-effect/dist/core";
import LayoutNew from "components/LayoutNew";
import { postWordDoc } from "api/services/wordDocsService";
import { format } from "date-fns";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { ArrowUpIcon, VerticalLinesIcon } from "components/IconsNew";
import EmptyChatContent from "components/EmptyChatContent";
import { GreyText } from "pages/chat";

const StyledCenteredWithTopNavLayout = styled(LayoutNew)`
  /* padding: 0;
  display: grid;
  background-color: #f7f9fd;
  height: 100vh; */
`;

const CenteredContent = styled.div`
  /* display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
  grid-template-rows: 1fr auto 1fr; */
  /* background-color: #cdd4e0; */
  position: relative;
  width: 800px;
  margin: 0 auto;
  display: grid;
  padding-top: 0px;
  height: calc(100vh - 40px);
  grid-template-rows: 1fr 73px;
  transition: width 0.2s;
`;

const Title = styled.div`
  color: #000000;
  text-align: center;
  padding-bottom: 0px;

  font-size: 36px;
  font-weight: 700;
  margin-bottom: 14px;
  text-align: center;
`;

const SubTitle = styled.div`
  justify-content: center;
  text-align: center;

  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #6c7275;
`;

const StyledRecoInput = styled(InputWithBottomSuggestionsAndSources)`
  width: 800px;
  padding: 12px 32px;
  padding-right: 80px;
  padding-left: 48px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
  z-index: 1;
  max-height: 200px;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const InputContainer = styled.div`
  position: relative;
  /* width: 700px; */
  transition: opacity 1s;
`;

const MS_PER_LETTER = 40;
const MS_PAUSE = 3000;
const MS_DELETE = 4;

const START_TIME = 1000;

const INPUT_PLACEHOLDER = "Ask about the file...";

const SendButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
`;

const HomePageNew = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const [sources, setSources] = useState();
  const [userProfile, setUserProfile] = useState(null);
  const [isAnimating, setIsAnimating] = useSearchParamsState({
    paramName: "isAnimating",
    initialValue: false,
  });
  const [msSincePageLoad, setMsSincePageLoad] = useState(null);
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(true);
  const [isCreatingDoc, setIsCreatingDoc] = useState(false);
  const [userSources, setUserSources] = useState([]);
  const [inputOpacity, setInputOpacity] = useState(0);
  const [shouldSearchInternet, setShouldSearchInternet] = useState(false);
  const [shouldUseFiles, setShouldUseFiles] = useState(true);

  // const [isBlue, setIsBlue] = useState(false);

  useEffect(() => {
    if (isInvestorUser()) {
      navigate("/compare-models");
    }
  }, []);

  useEffect(() => {
    if (isAnimating !== true) {
      return;
    }

    const input = document.querySelector("#input");
    const title = document.querySelector("#title");
    const blueButtons = document.querySelectorAll(".blue-button");
    const suggestionsContainer = document.querySelector("#suggestions");
    const suggestions = document.querySelectorAll(".suggestion");
    const suggestionsHelp = document.querySelector("#suggestions-help");
    const suggestionsHelpTrigger = document.querySelector(
      "#suggestions-help-trigger"
    );

    const sources = document.querySelectorAll(".source");
    const sourcesHelp = document.querySelector("#sources-help");
    const sourcesHelpTrigger = document.querySelector("#sources-help-trigger");
    const tokensText = document.querySelector("#tokens-text");

    const suggestionsList = document.querySelector("#suggestions-list");

    suggestionsList.style.opacity = 0;
    blueButtons.forEach(button => {
      button.style.opacity = 0;
    });
    input.style.opacity = 0;
    input.placeholder = "";
    // title.style.opacity = 0;
    suggestionsContainer.style.opacity = 0;
    suggestions.forEach(suggestion => {
      suggestion.style.color = "white";
    });
    suggestionsHelp.style.opacity = 0;
    suggestionsHelpTrigger.style.opacity = 0;

    sources.forEach(source => {
      source.style.opacity = 0;
    });
    sourcesHelp.style.opacity = 0;
    sourcesHelpTrigger.style.opacity = 0;
    tokensText.style.opacity = 0;

    const centeredContent = document.querySelector("#centered-content");
    centeredContent.style.opacity = 1;

    setMsSincePageLoad(0);
    const intervalId = setInterval(() => {
      setMsSincePageLoad(prev => (prev || 0) + 200);
    }, 200);

    return () => clearInterval(intervalId);
  }, [isAnimating]);

  useEffect(() => {
    if (isNil(msSincePageLoad)) {
      return;
    }

    const input = document.getElementById("input");
    const title = document.getElementById("title");
    const blueButtons = document.querySelectorAll(".blue-button");
    const attachButton = document.querySelector("#attach-button");
    const suggestionsContainer = document.querySelector("#suggestions");
    const suggestions = document.querySelectorAll(".suggestion");
    const suggestionsHelp = document.querySelector("#suggestions-help");
    const suggestionsHelpTrigger = document.querySelector(
      "#suggestions-help-trigger"
    );

    const sources = document.querySelectorAll(".source");
    const sourcesHelp = document.querySelector("#sources-help");
    const sourcesHelpTrigger = document.querySelector("#sources-help-trigger");
    const tokensText = document.querySelector("#tokens-text");
    const suggestionsList = document.querySelector("#suggestions-list");

    if (msSincePageLoad === START_TIME) {
      const newTypewriter = new Typewriter("#title", {
        cursor: "",
      });
      let textToType = "What can I do for you?";
      if (userProfile?.name) {
        textToType = `What can I do for you, ${userProfile?.name}?`;
      }
      newTypewriter.changeDelay(MS_PER_LETTER).typeString(textToType).start();
    }

    if (msSincePageLoad > START_TIME + 1000) {
      // title.style.transition = "opacity 0.5s";
      // title.style.opacity = 1;
    }

    if (msSincePageLoad > START_TIME + 2000) {
      input.style.transition = "opacity 0.5s";
      input.style.opacity = 1;
    }

    if (msSincePageLoad > START_TIME + 3000) {
      suggestionsContainer.style.transition = "opacity 0.5s";
      suggestionsContainer.style.visibility = "visible";
      suggestionsContainer.style.opacity = 1;

      input.style.bottomBorderLeftRadius = 0;
      input.style.bottomBorderRightRadius = 0;
      input.style.borderBottom = "1px solid transparent";
      input.style.boxShadow = "none";
    }

    if (msSincePageLoad > START_TIME + 4000) {
      attachButton.style.transition = "opacity 0.5s";
      attachButton.style.opacity = 1;
      sources?.forEach(source => {
        source.style.transition = "opacity 0.6s";
        source.style.opacity = 1;
      });
      sourcesHelp.style.transition = "opacity 0.6s";
      searchParams.set("isShowingSourcesHelp", true);
      navigate(`?${searchParams.toString()}`);

      sourcesHelpTrigger.style.transition = "opacity 0.5s";
      sourcesHelpTrigger.style.opacity = 1;
      tokensText.style.transition = "opacity 0.5s";
      tokensText.style.opacity = 1;
    }

    if (msSincePageLoad > START_TIME + 5000) {
      input.placeholder = INPUT_PLACEHOLDER;
      suggestions?.forEach((suggestion, i) => {
        setTimeout(() => {
          suggestion.style.transition = "color 0.5s";
          suggestion.style.color = "#000";
        }, i * 300);
      });
    }

    if (msSincePageLoad > START_TIME + 5000 + suggestions.length * 300) {
      suggestionsHelp.style.transition = "opacity 0.5s";
      searchParams.set("isShowingSuggestionsHelp", true);
      navigate(`?${searchParams.toString()}`);

      suggestionsList.style.transition = "opacity 0.5s";
      suggestionsList.style.opacity = 1;

      suggestionsHelpTrigger.style.transition = "opacity 0.5s";
      suggestionsHelpTrigger.style.opacity = 1;
    }

    if (msSincePageLoad > START_TIME + 8000) {
      blueButtons.forEach(button => {
        button.style.transition = "opacity 0.5s";
        button.style.opacity = "";
      });
    }

    if (msSincePageLoad > START_TIME + 9000) {
      input.style = {};
      input.focus();
      suggestionsContainer.style.visibility = "";
      suggestionsHelp.style.transition = "";
      sourcesHelp.style.transition = "";

      searchParams.set("isAnimating", false);
      searchParams.delete("isShowingSuggestionsHelp");
      searchParams.delete("isShowingSourcesHelp");
      navigate(`?${searchParams.toString()}`);
    }
  }, [msSincePageLoad]);

  useEffect(() => {
    doFetchUserProfile();
  }, []);

  const doFetchUserProfile = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
    setIsLoadingUserProfile(false);
  };

  const openSidebarType = parseJson(searchParams.get("openSidebarType"));

  let greetingText = "What can I do for you?";
  if (userProfile?.name) {
    greetingText = `What can I do for you, ${userProfile?.name}?`;
  }
  if (isAnimating) {
    greetingText = "";
  }

  const wordDocPayload = {
    directoryPath: "/working-files",
    fileName: `Chat on ${format(new Date(), "dd MMM HH:mm")}`,
    isChat: true,
    content: {
      sources,
      blocks: [
        {
          text: searchValue,
          isQuery: true,
          userSources,
          styles: [
            {
              isSelection: true,
              start: searchValue?.length,
              end: searchValue?.length,
            },
          ],
        },
      ],
    },
  };

  const onKeyDown = async e => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      setIsCreatingDoc(true);
      searchParams.set("initialQuery", searchValue);
      // navigate(`/quick-chat-stream?${searchParams?.toString()}`);
      const { data } = await postWordDoc({}, wordDocPayload);
      setIsCreatingDoc(false);
      navigate(
        `/chat-docs/${data?.id}?shouldSearchInternet=${shouldSearchInternet}`
      );
    }
  };

  const isSendDisabled = !searchValue || isCreatingDoc;

  const inputElement = (
    <InputContainer style={{ opacity: inputOpacity }}>
      <StyledRecoInput
        id="input"
        placeholder={"Ask a question"}
        value={searchValue}
        sources={sources}
        setSources={newSources => {
          setUserSources(newSources);
          setSources(newSources);
        }}
        onChange={e => setSearchValue(e.target.value)}
        onClickSuggestion={text => setSearchValue(text)}
        onKeyDown={onKeyDown}
        userSources={userSources}
        setUserSources={setUserSources}
        shouldSearchInternet={shouldSearchInternet}
        setShouldSearchInternet={setShouldSearchInternet}
        shouldUseFiles={shouldUseFiles}
        setShouldUseFiles={setShouldUseFiles}
        canUseDb={false}
      />
      <GreyText>
        The model can make mistakes. Check important info.&nbsp;
        <a href="/terms" target="_blank">
          Terms of Services
        </a>
        &nbsp;and&nbsp;
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </GreyText>
    </InputContainer>
  );

  return (
    <StyledCenteredWithTopNavLayout
      isTopBarHidden
      isHeaderEnabled={false}
      logoSrc={userProfile?.organisation}
    >
      <CenteredContent
        id="centered-content"
        style={{
          opacity: isAnimating || isLoadingUserProfile ? 0 : 1,
          pointerEvents: isAnimating ? "none" : "auto",
        }}
      >
        <EmptyChatContent
          onAnimationFinish={async () => {
            await sleep(100);
            setInputOpacity(1);
          }}
        />
        {inputElement}
      </CenteredContent>
    </StyledCenteredWithTopNavLayout>
  );
};

export default HomePageNew;
