import styled from "styled-components";
import { isEmpty } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

import { parseJson } from "utils/common";
import LayoutApp from "components/LayoutApp";
import ChatViewNew from "components/ChatViewNew";

const Container = styled.div`
  position: relative;
  width: 800px;
  margin: 0 auto;
  display: grid;
  padding-top: 0px;
  height: calc(100vh - 40px);
  transition: width 0.2s;
`;

const AppChatPageNew = () => {
  const [searchParams] = useSearchParams();
  const { pipelineConfigId } = useParams();

  const openSidebarType = parseJson(searchParams?.get("openSidebarType"));
  const isSideBarOpen = !!openSidebarType && !isEmpty(openSidebarType);

  return (
    <LayoutApp
      bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);"
      topBarBgColor="#f3f5f7"
    >
      <Container isSideBarOpen={isSideBarOpen}>
        <ChatViewNew pipelineConfigId={pipelineConfigId} />
      </Container>
    </LayoutApp>
  );
};

export default AppChatPageNew;
