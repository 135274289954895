import { FileCopy, Fullscreen } from "@material-ui/icons";
import {
  getPipelineConfig,
  pipelineConfigExecuteNlc,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import AreaChartParams from "components/AreaChartParams";
import AreaChartPercentageParams from "components/AreaChartPercentageParams";
import BarChartParams from "components/BarChartParams";
import ChatModal from "components/ChatModal";
import ChatView from "components/ChatView";
import { Reply } from "components/FieldReferenceModal";
import GridDraggable from "components/GridDraggable";
import {
  ArrowUpIcon,
  BinIcon,
  ChatIcon,
  MagnifyingGlassIcon,
  MoveIcon,
} from "components/IconsNew";
import KeyFiguresParams from "components/KeyFiguresParams";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import LineChartParams from "components/LineChartParams";
import RecordsPlotSql from "components/RecordsPlotSql";
import { PLOT_TYPES, getPlotComponent } from "components/plots";
import ButtonWord from "components/ui/ButtonWord";
import {
  CrossIcon,
  FilesIcon,
  PencilIcon,
  PlusIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import TooltipNew from "components/ui/TooltipNew";
import { cloneDeep, last } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromString, uuidv4 } from "utils/common";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow: hidden;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.7;"}
`;

const ModalContent = styled.div`
  position: relative;
  width: 1000px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 400px;
  align-content: start;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const LeftContainer = styled.div`
  overflow: auto;
  display: grid;
  gap: 10px;
  padding: 32px;
  border-right: 1px solid #ccc;
  background-color: #f3f5f7;
  border-top-left-radius: 24px;
`;

const RightContainer = styled.div`
  overflow: auto;
  display: grid;
  align-content: start;
  gap: 10px;
  padding: 32px;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SideNav = styled.div`
  border-right: 1px solid #ccc;
  z-index: 1;
`;

const RecordFields = styled.div`
  padding: 20px;
  padding-top: 60px;
  display: flex;
  gap: 20px;
  position: relative;
`;

const SimpleButton = styled.button`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  outline: none;
  font-weight: 500;

  color: #b9b9b9;
  svg {
    height: 14px;
    fill: #b9b9b9;
  }

  :hover {
    background-color: #b9b9b9;
    color: white;
    svg {
      fill: white;
    }
  }

  ${props =>
    props.isPrimary &&
    `
    background-color: #0191FF; 
    color: white;
    border: 1px solid #0191FF;
    :hover {
      background-color: #0191FF;
      color: white;
    }
    `}
`;

const GroupCard = styled.div`
  background-color: #ffffff;
  /* padding: 10px; */
  border-radius: 10px;
  height: 100%;
  align-content: start;
  display: grid;
  gap: 10px;
  border: 1px solid #dedede;

  ${SimpleButton} {
    opacity: 0;
    transition: opacity 0.2s;
  }
  :hover {
    ${SimpleButton} {
      opacity: 1;
    }
  }
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color || "#f3f5f7"}22;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 4px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom: 1px solid #dedede;
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis; */
`;

const Field = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;
  color-scheme: dark;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldLabel = styled.label`
  font-weight: 600;
  color: #a2a2a2;
`;

const SideNavItem = styled.div`
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  ${props => props.isSelected && "background-color: #f5f5f5;"}
  :hover {
    background-color: #eaeaea;
  }
`;

const StyledButtonWord = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 8px;
  svg {
    fill: white;
    height: 14px;
  }
`;

const TopBar = styled.div`
  position: relative;
  height: 54px;
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  box-shadow: 0px 12px 40px -12px #0000000f;

  border-left: 1px solid #e8ecef;
  border-bottom: 1px solid #e8ecef;
`;

const Td = styled.td`
  border: 1px solid #ccc;
  padding: 4px;
  text-align: left;
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px;
  border: 1px solid #ccc;
  text-align: left;
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

const StyledInput = styled.input`
  height: 42px;
  width: 100%;
  background-color: white;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const StyledSelect = styled.select`
  height: 52px;
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const SqlArea = styled.textarea`
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  white-space: pre-wrap;
  font-weight: 500;
  line-height: 1.2;
  color: #6e6e6e;
  height: 200px;
  overflow: auto;
  border: none;
  resize: none;
  :focus {
    color: #292929;
  }
`;

const PageTitle = styled.div`
  font-weight: 600;
  color: #b9b9b9;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColumnCards = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  /* height: 115px; */
  height: 60px;
  overflow: auto;
  padding-bottom: 10px;
  white-space: nowrap;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const ColumnCard = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: grid;
  gap: 4px;
  background-color: ${props => props?.bgColor}44;
  cursor: pointer;
  :hover {
    background-color: ${props => props?.bgColor}99;
  }
`;

const ColumnTitle = styled.div`
  font-weight: 500;
`;

const ColumnDataType = styled.div`
  opacity: 0.5;
`;

const BottomStickyButtons = styled.div`
  grid-column: span 2;
  position: sticky;
  bottom: 5px;
  display: flex;
  justify-content: end;
  width: 100%;
  background-color: white;
  gap: 10px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top: 1px solid #ccc;
  padding: 10px;
`;

const EmptyAggComponent = styled.div`
  border: 2px dashed #ccc;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-right: 20px;
  color: #ccc;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 20px;
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: white;
  svg {
    fill: #424242;
  }
  :hover {
    color: ${props => props.theme.color.primary};
    border-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242; 
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const GridContainer = styled.div`
  height: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
  box-shadow: 0px 24px 60px 0px #0000001a;
  border-left: 1px solid #e8ecef;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const Message = styled.div`
  font-weight: 500;
  line-height: 1.2;
  white-space: pre-wrap;
  color: ${props => props.theme.color.in_progress};
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: #eaeaea;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: #eaeaea;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: #eaeaea;
  }
`;

const StyledSearchInput = styled.input`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 14px;
  width: 600px;
  font-weight: 400;
  margin-top: 0px;
  font-family: "Montserrat";
  outline: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #dddddd, #dddddd) border-box;
  border-radius: 12px;
  border: 1px solid transparent;

  :focus {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  }
`;

const Slideout = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: calc(100vh - 40px - 54px);
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom-right-radius: 24px;
  z-index: 10;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const InputAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

export const reshapeRecords = recordsWithFields => {
  return (
    recordsWithFields?.map(record => ({
      ...record,
      ...(record?.fields || {}),
    })) || []
  );
};

const INITIAL_PLOTS = [
  {
    type: "Table",
    xColumnName: "",
    yColumnNames: [],
  },
];
const EditAggComponentModal = ({
  initialIsOpen = false,
  onClose = () => {},
  pipelineConfig = {},
  aggComponent = {},
  onPressSave = () => {},
  trigger = <PencilIcon />,
  isTriggerAlwaysVisible = false,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [editedComponent, setEditedComponent] = useState(aggComponent);
  const [isExecuting, setIsExecuting] = useState(false);
  const [execResult, setExecResult] = useState({});
  const [editedSql, setEditedSql] = useState("");
  const [isEditingSql, setIsEditingSql] = useState(false);
  const nlcRef = useRef(null);

  const [plots, setPlots] = useState(INITIAL_PLOTS);

  useEffect(() => {
    setExecResult(aggComponent?.data?.execResult || {});
    setEditedSql(aggComponent?.data?.execResult?.sql || "");
    setPlots(aggComponent?.data?.plots || INITIAL_PLOTS);
  }, [JSON.stringify(aggComponent)]);

  useEffect(() => {
    if (!isOpen) {
      onClose();
      return;
    }
    doPopulateExecResult({ sqlQuery: aggComponent?.data?.execResult?.sql });
  }, [isOpen]);

  const doPopulateExecResult = async ({ nlc, sqlQuery }) => {
    if (!nlc && !sqlQuery) {
      return;
    }
    setIsExecuting(true);
    setExecResult({});
    const { data } = await pipelineConfigExecuteNlc(
      pipelineConfig?.id,
      {},
      { nlc, sqlQuery }
    );
    setExecResult(data);
    setEditedSql(data?.sql);
    setIsExecuting(false);

    if (nlc && data?.displayConfig) {
      const newPlots = cloneDeep(plots) || [];
      newPlots[0] = data?.displayConfig;
      setPlots(newPlots);
    }
  };

  const onClickPlus = () => {
    setPlots([
      ...plots,
      {
        type: "Table",
        xColumnName: "",
        yColumnNames: [],
      },
    ]);
  };

  const onClickCross = index => {
    const newPlots = [...plots];
    newPlots.splice(index, 1);
    setPlots(newPlots);
  };

  const availableColumns = pipelineConfig?.sourceTables?.[0]?.columns || [];
  const tableColumns = Object.entries(execResult?.columnTypes || {}).map(
    ([key, value]) => ({
      name: key,
    })
  );

  const plotsContent = (
    <>
      {plots.map((plot, index) => {
        const plotType = plot?.type;
        let plotProps = {
          params: plot,
          isEditing: true,
          onChangeParams: newParams => {
            const newPlots = [...plots];
            newPlots[index] = newParams;
            setPlots(newPlots);
          },
          tableColumns,
          records: reshapeRecords(execResult?.records),
        };
        let plotContent = (
          <RecordsPlotSql
            type={plotType}
            records={execResult?.records || []}
            columnTypes={execResult?.columnTypes || {}}
          />
        );
        if (plotType !== "Table") {
          plotContent = getPlotComponent(plotType, plotProps);
        }

        return (
          <div key={index} style={{ marginBottom: 40 }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <StyledSelect
                style={{ height: 32 }}
                value={plot?.type}
                onChange={e =>
                  setPlots(
                    plots.map((p, i) =>
                      i === index ? { ...p, type: e.target.value } : p
                    )
                  )
                }
              >
                {PLOT_TYPES.map((plotType, index) => (
                  <option key={plotType} value={plotType}>
                    {plotType}
                  </option>
                ))}
              </StyledSelect>
              <StyledCrossIcon onClick={() => onClickCross(index)} />
            </div>
            {plotContent}
          </div>
        );
      })}
    </>
  );

  const onClickTrigger = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(true);
  };

  const onClickColumnReco = column => {
    let nlcWords = editedComponent?.nlc?.split(" ");
    nlcWords[nlcWords?.length - 1] = column?.displayName;

    setEditedComponent({
      ...editedComponent,
      nlc: nlcWords?.join(" "),
    });

    nlcRef?.current?.focus();
  };

  const llmPromptReplies =
    execResult?.llmPromptReplies ||
    aggComponent?.data?.execResult?.llmPromptReplies ||
    [];

  const sqlEditor = (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          gap: 10,
          alignItems: "center",
        }}
      >
        <BoldDiv>Step 1: SQL</BoldDiv>
        {isEditingSql ? (
          <StyledCrossIcon
            onClick={() => {
              setIsEditingSql(false);
              setEditedSql(execResult?.sql);
            }}
            style={{ height: "14px" }}
          />
        ) : (
          <StyledPencilIcon
            onClick={() => setIsEditingSql(true)}
            style={{ height: "14px" }}
          />
        )}
        <ButtonWord
          disabled={!isEditingSql}
          onClick={() => doPopulateExecResult({ sqlQuery: editedSql })}
        >
          Run SQL
        </ButtonWord>
      </div>
      <SqlArea
        value={editedSql}
        onChange={e => setEditedSql(e.target.value)}
        disabled={!isEditingSql}
      />
    </>
  );
  let pythonEditor = null;
  if (execResult?.pythonCode) {
    pythonEditor = (
      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            gap: 10,
            alignItems: "center",
          }}
        >
          <BoldDiv>Step 2: Python</BoldDiv>
          {isEditingSql ? (
            <StyledCrossIcon
              onClick={() => {
                setIsEditingSql(false);
                // setEditedSql(execResult?.pythonCode);
              }}
              style={{ height: "14px" }}
            />
          ) : (
            <StyledPencilIcon
              onClick={() => setIsEditingSql(true)}
              style={{ height: "14px" }}
            />
          )}
          <ButtonWord disabled={!isEditingSql} onClick={() => {}}>
            Run Python
          </ButtonWord>
        </div>
        <SqlArea
          value={execResult?.pythonCode}
          // onChange={e => setEditedSql(e.target.value)}
          disabled={!isEditingSql}
        />
      </>
    );
  }

  return (
    <>
      {isTriggerAlwaysVisible && (
        <ButtonWord style={{ padding: 8 }} onClick={onClickTrigger} isPrimary>
          Configure
        </ButtonWord>
      )}
      {!isTriggerAlwaysVisible && (
        <SimpleButton onClick={onClickTrigger}>{trigger}</SimpleButton>
      )}
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <LeftContainer>
            <Title>Edit Component</Title>
            <Gap height="10px" />

            <TwoColumns>
              <BoldDiv>Name</BoldDiv>
              <div />
              <StyledInput
                placeholder="New name"
                value={editedComponent?.label}
                onChange={e =>
                  setEditedComponent({
                    ...editedComponent,
                    label: e.target.value,
                  })
                }
              />
            </TwoColumns>
            <Gap height="10px" />
            <BoldDiv>What would you like to do?</BoldDiv>
            <InputAndButton>
              <StyledInput
                ref={nlcRef}
                placeholder="Type here..."
                value={editedComponent?.nlc}
                onChange={e =>
                  setEditedComponent({
                    ...editedComponent,
                    nlc: e.target.value,
                  })
                }
              />
              <ButtonWord
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifySelf: "end",
                }}
                isPrimary
                disabled={isExecuting}
                onClick={() =>
                  doPopulateExecResult({ nlc: editedComponent?.nlc })
                }
              >
                <ArrowUpIcon />
              </ButtonWord>
            </InputAndButton>
            <ColumnCards>
              {availableColumns
                ?.filter(col => {
                  let lastWord = last(editedComponent?.nlc?.split(" "));
                  return col?.displayName
                    ?.toLowerCase()
                    ?.includes(lastWord?.toLowerCase());
                })
                ?.map(column => (
                  <ColumnCard
                    onClick={() => onClickColumnReco(column)}
                    key={column?.dbName}
                    bgColor={getColorFromString(column?.formatType || "Text")}
                  >
                    <ColumnTitle>{column?.displayName}</ColumnTitle>
                    <ColumnDataType>
                      {column?.formatType || "Text"}
                    </ColumnDataType>
                  </ColumnCard>
                ))}
            </ColumnCards>
            <BoldDiv>Chain of Thought</BoldDiv>
            {sqlEditor}
            {pythonEditor}
            {/* <BoldDiv>Chain of Thought</BoldDiv>
            {llmPromptReplies?.map((reply, i) => (
              <Reply key={i} reply={reply} index={i} />
            ))}
            {!llmPromptReplies?.length && <div>No steps</div>} */}
          </LeftContainer>
          <RightContainer>
            <Title>Preview</Title>
            <Gap height="10px" />
            {plotsContent}
            <StyledPlusIcon onClick={onClickPlus} />
            <Message>{execResult?.message}</Message>
          </RightContainer>

          <BottomStickyButtons>
            <ButtonWord
              style={{ padding: "4px 20px" }}
              isPrimary
              onClick={() => {
                editedComponent.data = {
                  ...(editedComponent.data || {}),
                  execResult,
                  plots,
                  updatedAt: new Date().toISOString(),
                };
                onPressSave(editedComponent);
                setIsOpen(false);
              }}
            >
              Confirm
            </ButtonWord>
            <ButtonWord
              onClick={() => {
                setIsOpen(false);
                setEditedComponent(aggComponent);
              }}
              style={{ padding: "4px 20px" }}
            >
              Cancel
            </ButtonWord>
          </BottomStickyButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

const InpectModalContent = styled.div`
  width: 1000px;
  /* height: 90vh; */
  max-height: 90vh;
  overflow: auto;
  display: grid;
  grid-template-rows: auto auto 1fr;
  align-content: center;
  align-items: center;
`;

const ComponentNlc = styled.div`
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
`;

const NlcLabel = styled.div`
  font-size: 14px;
  color: ${props => props.theme.color.primary};
  margin-bottom: 8px;
`;

const AggregationComponent = ({
  aggComponent = {},
  pipelineConfig = {},
  onSaveComponent = () => {},
  onClickDuplicate = () => {},
  onClickDelete = () => {},
}) => {
  const [execResult, setExecResult] = useState({});
  const [isInspectModalOpen, setIsInspectModalOpen] = useState(false);

  const doPopulateExecResult = async () => {
    const { data } = await pipelineConfigExecuteNlc(
      pipelineConfig?.id,
      {},
      {
        sqlQuery: aggComponent?.data?.execResult?.sql,
      }
    );
    setExecResult(data);
  };

  useEffect(() => {
    if (!aggComponent?.nlc) {
      return;
    }

    doPopulateExecResult();
  }, [aggComponent?.nlc]);

  if (!aggComponent?.nlc) {
    return (
      <GroupCard style={{ gridTemplateRows: "1fr" }}>
        <EmptyAggComponent>
          Empty Plot
          <EditAggComponentModal
            pipelineConfig={pipelineConfig}
            aggComponent={aggComponent}
            onPressSave={newComponent => {
              setExecResult(newComponent?.data?.execResult || {});
              onSaveComponent(newComponent);
            }}
            isTriggerAlwaysVisible
          />
        </EmptyAggComponent>
      </GroupCard>
    );
  }

  const tableColumns = Object.entries(execResult?.columnTypes || {}).map(
    ([key, value]) => ({
      name: key,
    })
  );

  const isFirstPlotTable = aggComponent?.data?.plots?.[0]?.type === "Table";

  const plotsContent = (
    <div
      style={{
        padding: 10,
        paddingTop: 0,
        overflow: isFirstPlotTable ? "auto" : "hidden",
      }}
    >
      {aggComponent?.data?.plots?.map((plot, index) => {
        const plotType = plot?.type;
        let plotProps = {
          params: plot || {},
          isEditing: false,
          onChangeParams: () => {},
          tableColumns,
          records: reshapeRecords(execResult?.records),
          height: isInspectModalOpen ? 300 : 180,
        };

        let plotContent = (
          <RecordsPlotSql
            type={plot?.type}
            records={execResult?.records}
            columnTypes={execResult?.columnTypes}
            isLoading={!execResult?.records}
          />
        );
        if (plotType !== "Table") {
          plotContent = getPlotComponent(plotType, plotProps);
        }

        return (
          <div key={index} style={{ marginBottom: 10 }}>
            {plotContent}
          </div>
        );
      })}
    </div>
  );

  const doesPlotHaveKeyFigures = aggComponent?.data?.plots?.some(
    plot => plot?.type === "Key Figures"
  );

  if (isInspectModalOpen) {
    return (
      <Modal open handleClose={() => setIsInspectModalOpen(false)}>
        <InpectModalContent>
          <GroupTitle
            color={getColorFromString(aggComponent?.label)}
            style={{
              display: "grid",
              alignItems: "center",
              gap: 10,
              gridTemplateColumns: "1fr auto",
              padding: 20,
            }}
          >
            {aggComponent?.label}
            <SimpleButton onClick={() => setIsInspectModalOpen(false)}>
              <CrossIcon style={{ height: "10px" }} />
            </SimpleButton>
          </GroupTitle>
          <ComponentNlc>
            <NlcLabel>Description / prompt:</NlcLabel>
            <div>{aggComponent?.nlc}</div>
          </ComponentNlc>
          <div style={{ padding: "0 14px" }}>{plotsContent}</div>
        </InpectModalContent>
      </Modal>
    );
  }

  return (
    <GroupCard
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <GroupTitle
        color={getColorFromString(aggComponent?.label)}
        style={{
          display: "grid",
          alignItems: "center",
          gap: 10,
          gridTemplateColumns: "1fr auto auto auto auto auto",
        }}
      >
        {aggComponent?.label}
        <TooltipNew tipText="Copy command">
          <SimpleButton
            onClick={() => navigator.clipboard.writeText(aggComponent?.nlc)}
          >
            <FilesIcon />
          </SimpleButton>
        </TooltipNew>
        {doesPlotHaveKeyFigures ? (
          <div />
        ) : (
          <SimpleButton onClick={() => setIsInspectModalOpen(true)}>
            <Fullscreen />
          </SimpleButton>
        )}
        <SimpleButton onClick={onClickDuplicate}>
          <FileCopy />
        </SimpleButton>
        <EditAggComponentModal
          pipelineConfig={pipelineConfig}
          aggComponent={aggComponent}
          onPressSave={newComponent => {
            setExecResult(newComponent?.data?.execResult || {});
            onSaveComponent(newComponent);
          }}
        />
        <SimpleButton onClick={onClickDelete}>
          <BinIcon />
        </SimpleButton>
      </GroupTitle>
      {plotsContent}
    </GroupCard>
  );
};

const updateLayout = (pipelineConfig = {}, newLayout = {}) => {
  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.meta = {
    ...(newPipelineConfig.meta || {}),
    dashboardLayout: newLayout,
  };
  return newPipelineConfig;
};

const addNewComponent = (pipelineConfig = {}, coords = { x: 0, y: 0 }) => {
  const newPipelineConfig = cloneDeep(pipelineConfig);

  // add to list of components
  const newComponent = {
    id: uuidv4(),
    label: "Untitled Plot",
    type: "Table",
    nlc: "",
    data: {},
  };
  newPipelineConfig.aggregationComponents = [
    ...(newPipelineConfig.aggregationComponents || []),
    newComponent,
  ];

  // place it on grid
  const newLayout = newPipelineConfig.meta?.dashboardLayout || {};
  newLayout[newComponent.id] = {
    x: coords.x,
    y: coords.y,
    w: 24,
    h: 14,
  };
  newPipelineConfig.meta = {
    ...(newPipelineConfig.meta || {}),
    dashboardLayout: newLayout,
  };

  return newPipelineConfig;
};

const addNewComponentWithFields = (
  pipelineConfig = {},
  coords = { x: 0, y: 0, w: 24, h: 14 },
  componentFields = {}
) => {
  const newPipelineConfig = cloneDeep(pipelineConfig);

  // add to list of components
  const newComponent = {
    ...componentFields,
    id: uuidv4(),
  };
  newPipelineConfig.aggregationComponents = [
    ...(newPipelineConfig.aggregationComponents || []),
    newComponent,
  ];

  // place it on grid
  const newLayout = newPipelineConfig.meta?.dashboardLayout || {};
  newLayout[newComponent.id] = {
    x: coords.x,
    y: coords.y,
    w: coords?.w || 24,
    h: coords?.h || 14,
  };
  newPipelineConfig.meta = {
    ...(newPipelineConfig.meta || {}),
    dashboardLayout: newLayout,
  };

  return newPipelineConfig;
};

const isSpaceAvailable = (dashboardLayout = {}, { x, y }) => {
  const w = 24;
  const h = 14;
  const overlappingRect = Object.values(dashboardLayout).find(rect => {
    const isOverlapping =
      x < rect.x + rect.w + 2 &&
      x + w > rect.x &&
      y < rect.y + rect.h + 2 &&
      y + h > rect.y;
    return isOverlapping;
  });

  return !overlappingRect;
};

const getAvailableCoords = pipelineConfig => {
  const dashboardLayout = pipelineConfig?.meta?.dashboardLayout || {};

  let x = 1;
  let y = 1;
  while (!isSpaceAvailable(dashboardLayout, { x, y })) {
    x += 1;
    if (x >= 90) {
      x = 1;
      y += 1;
    }

    if (y > 400) {
      break;
    }
  }

  return { x, y };
};

const updateComponent = (pipelineConfig = {}, updatedComponent = {}) => {
  const newPipelineConfig = cloneDeep(pipelineConfig);

  const componentIndex = newPipelineConfig.aggregationComponents.findIndex(
    component => component.id === updatedComponent.id
  );
  newPipelineConfig.aggregationComponents[componentIndex] = updatedComponent;

  return newPipelineConfig;
};

const removeComponent = (pipelineConfig = {}, key = "") => {
  const newPipelineConfig = cloneDeep(pipelineConfig);

  delete newPipelineConfig.meta?.dashboardLayout[key];
  newPipelineConfig.aggregationComponents =
    newPipelineConfig.aggregationComponents.filter(
      component => component.id !== key
    );

  return newPipelineConfig;
};

const AppDashboardPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { pipelineConfigId } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState("");

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  const doPopulatePipelineConfig = async () => {
    setIsLoading(true);
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
    setIsLoading(false);
  };

  const doPutPipelineConfig = async newPipelineConfig => {
    setIsLoading(true);
    const { data } = await putPipelineConfig(
      pipelineConfigId,
      {},
      newPipelineConfig
    );
    setPipelineConfig(data);
    setIsLoading(false);
  };

  const onDragEnd = newLayout => {
    const newPipelineConfig = updateLayout(pipelineConfig, newLayout);
    doPutPipelineConfig(newPipelineConfig);
  };

  const onClickEmptyCell = ({ x, y }) => {
    const newPipelineConfig = addNewComponent(pipelineConfig, { x, y });
    doPutPipelineConfig(newPipelineConfig);
  };

  const onDeleteKey = key => {
    const newPipelineConfig = removeComponent(pipelineConfig, key);
    doPutPipelineConfig(newPipelineConfig);
  };

  const onSaveComponent = newFields => {
    const newPipelineConfig = updateComponent(pipelineConfig, newFields);
    doPutPipelineConfig(newPipelineConfig);
  };

  const onPressSaveNewComponent = newComponent => {
    const { x, y } = getAvailableCoords(pipelineConfig);
    const newPipelineConfig = addNewComponentWithFields(
      pipelineConfig,
      { x, y },
      newComponent
    );
    doPutPipelineConfig(newPipelineConfig);
  };

  const onClickDuplicate = newComponent => {
    const newFields = cloneDeep(newComponent);
    const cmpRect = pipelineConfig?.meta?.dashboardLayout?.[newComponent.id];
    const newPipelineConfig = addNewComponentWithFields(
      pipelineConfig,
      { x: cmpRect?.x + 4, y: cmpRect?.y + 4, w: cmpRect?.w, h: cmpRect?.h },
      newFields
    );
    doPutPipelineConfig(newPipelineConfig);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(
        `/apps/${pipelineConfigId}/dashboard?initialQuery=${initialPrompt}`
      );
    }
  };

  return (
    <LayoutApp>
      <Container isDisabled={isLoading}>
        <TopBar>
          <PageTitle>Analytics</PageTitle>
          <StyledSearchInput
            value={initialPrompt}
            onChange={e => setInitialPrompt(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder="Ask a question, or give command"
          />
          <ChatModal />
        </TopBar>
        <GridContainer>
          <GridDraggable
            initialLayout={pipelineConfig?.meta?.dashboardLayout || {}}
            isEditingDisabled={!isEditingLayout}
            onDragEnd={onDragEnd}
            areItemsRemovable
            onDeleteKey={onDeleteKey}
            onClickEmptyCell={onClickEmptyCell}
            style={{
              width: "2000px",
              height: "1300px",
            }}
          >
            {pipelineConfig?.aggregationComponents?.map(aggComponent => (
              <AggregationComponent
                pipelineConfig={pipelineConfig}
                key={aggComponent.id}
                aggComponent={aggComponent}
                onSaveComponent={onSaveComponent}
                onClickDuplicate={() => onClickDuplicate(aggComponent)}
                onClickDelete={() => onDeleteKey(aggComponent.id)}
              />
            ))}
          </GridDraggable>
        </GridContainer>

        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            gap: 10,
            zIndex: 1,
          }}
        >
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            <MoveIcon />
            Rearrange
          </ToggleDiv>
        </div>

        <div style={{ display: "none" }}>
          <EditAggComponentModal
            initialIsOpen={searchParams?.get("addNew") === "true"}
            pipelineConfig={pipelineConfig}
            aggComponent={{
              label: "Untitled Plot",
              type: "Table",
              nlc: "",
              data: {},
            }}
            onPressSave={onPressSaveNewComponent}
            onClose={() => {
              navigate(`/apps/${pipelineConfigId}/dashboard`);
            }}
          />
        </div>
        <Slideout isOpen={isChatOpen}>
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          {isChatOpen && (
            <ChatView
              abortController={abortController}
              setAbortController={setAbortController}
              isGenerating={isGenerating}
              setIsGenerating={setIsGenerating}
            />
          )}
        </Slideout>
      </Container>
    </LayoutApp>
  );
};

export default AppDashboardPage;
