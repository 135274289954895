import { isNil } from "lodash";
import styled from "styled-components";

import { WarningIcon } from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import { useRef } from "react";
import { useEffect } from "react";
import { getColorFromString } from "utils/common";
import RecordsTagsInput from "components/RecordTagsInput";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  align-items: center;
  padding-right: 0px;
  min-width: 20px;
  width: 100%;

  svg {
    height: 12px;
    fill: #8d8d8d;
    cursor: pointer;
    :hover {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldArea = styled.textarea`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 100px;
  resize: vertical;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldSelect = styled.select`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const TipContainer = styled.div`
  background-color: black;
  color: white;
  padding: 4px;
`;

const DisplayValue = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;
  width: max-content;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const DisplayValueLongText = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  font-weight: 500;

  white-space: wrap;
  height: 70px;
  line-height: 1.2;
  overflow: auto;
  text-overflow: ellipsis;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const renderLinks = text => {
  return text?.replace(
    /https?:\/\/[^\s]+/g,
    url => `<a target="_blank" href="${url}">${url}</a>`
  );
};

const DATA_TYPE_TO_INPUT_TYPE = {
  TEXT: "text",
  NUMBER: "number",
  DATETIME: "datetime-local",
  TABLE: "table",
};

const RecordFieldInput = ({
  value = "",
  onChange = e => {},
  onBlur = e => {},
  column = {},
  style = {},
  rawValue = "",
  displayValue = "",
  isEditMode = false,
  isInline = false,
}) => {
  const inputRef = useRef(null);
  let type = DATA_TYPE_TO_INPUT_TYPE?.[column?.type] || "text";

  useEffect(() => {
    // if (isEditMode && inputRef?.current) {
    //   inputRef.current.focus();
    // }

    // if categorical value if broken, auto set it
    if (isEditMode && column?.formatType === "Categorical") {
      const allowedValues =
        column?.categories?.map(catStr => catStr?.split(",")?.[0]) || [];
      if (allowedValues?.includes(value)) {
        return;
      }

      const possibleValue = value?.split(",")?.[0];
      if (allowedValues?.includes(possibleValue)) {
        onChange({ target: { value: possibleValue } });
        return;
      }
    }
  }, [isEditMode, inputRef?.current, value, JSON.stringify(column)]);

  let bgColor = "transparent";
  if (column?.formatType === "Categorical") {
    const categoryStr = column?.categories?.find(
      c => c?.split(",")?.[0] === value
    );
    const categoryColor = categoryStr?.split(",")?.[1];
    bgColor = `${categoryColor}44`;
  }
  if (column?.formatType === "Rank") {
    const categoryStr = column?.categories?.[value];
    const categoryColor = categoryStr?.split(",")?.[1];
    bgColor = `${categoryColor}44`;
  }
  let bgStyle = { backgroundColor: bgColor };

  if (isEditMode) {
    if (column?.formatType === "Categorical" || column?.formatType === "Rank") {
      let categories = column?.categories || [];
      return (
        <Container style={style}>
          <FieldSelect
            ref={inputRef}
            style={bgStyle}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          >
            {categories?.map((option, i) => {
              const categoryName = option?.split(",")?.[0];
              let categoryValue = categoryName;
              if (column?.formatType === "Rank") {
                categoryValue = i;
              }

              return (
                <option key={option} value={categoryValue}>
                  {categoryName}
                </option>
              );
            })}
          </FieldSelect>
        </Container>
      );
    }

    if (column?.formatType === "Tags") {
      return (
        <Container style={style}>
          <RecordsTagsInput
            isEditMode
            column={column}
            value={value}
            onChange={onChange}
            isInline={isInline}
          />
        </Container>
      );
    }

    if (column?.formatType === "Long Text" && !isInline) {
      return (
        <Container style={style}>
          <FieldArea
            ref={inputRef}
            style={style}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onPaste={e => {
              // // print html content in clipboard
              // e.preventDefault();
              // let htmlText = (e.clipboardData || window.clipboardData).getData(
              //   "text/html"
              // );
              // onChange({ target: { value: htmlText } });
            }}
          />
        </Container>
      );
    }

    let inputValue = value;
    if (column?.type === "DATETIME") {
      inputValue = value?.replace("Z", "");
    }

    return (
      <Container style={style}>
        <FieldInput
          ref={inputRef}
          style={style}
          type={type}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Container>
    );
  }

  if (column?.formatType === "Rank") {
    displayValue = column?.categories?.[value]?.split(",")?.[0];
  }
  let valueToShow = renderLinks(displayValue || value);

  if (rawValue && (isNil(value) || value === "")) {
    return (
      <Container style={style}>
        <TooltipNew
          tipContent={
            <TipContainer>
              <div>Could not parse:</div>
              <br />
              <div>"{rawValue}"</div>
              <br />
              <div>Please fill in manually</div>
            </TipContainer>
          }
          style={{
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: 5,
            alignItems: "center",
          }}
        >
          <DisplayValue>{rawValue}</DisplayValue>
          <WarningIcon style={{ fill: "#d4a600", cursor: "default" }} />
        </TooltipNew>
      </Container>
    );
  }

  if (column?.formatType === "Long Text" && !isInline) {
    return (
      <Container style={style}>
        <DisplayValueLongText
          dangerouslySetInnerHTML={{
            __html: valueToShow,
          }}
        />
      </Container>
    );
  }

  if (column?.formatType === "Tags") {
    return (
      <Container style={style}>
        <RecordsTagsInput column={column} value={value} isInline={isInline} />
      </Container>
    );
  }

  return (
    <Container style={style}>
      <DisplayValue
        style={bgStyle}
        dangerouslySetInnerHTML={{
          __html: valueToShow,
        }}
      />
    </Container>
  );
};

export default RecordFieldInput;
