import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { cloneDeep, fill, isEqual, range } from "lodash";
import { Link, useSearchParams } from "react-router-dom";
import { Done, FileCopy, KeyboardArrowDown, Replay } from "@material-ui/icons";

import {
  BoltzbitLogo,
  BoltzhubLogoInner,
  CrossIcon,
  DatasetIcon,
  FilesIcon,
  LogoInner,
  PaperClipIcon,
  PencilIcon,
  PlusIcon,
  RoundTickIcon,
  SaveTickIcon,
  SendIcon,
  TickIcon,
} from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import { Gap } from "components/Layout";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import PagePreviewTextAndTableModalSpanTrigger from "components/PagePreviewTextAndTableModalTrigger";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  getCubesFindByKey,
  getUserProfileByUserName,
} from "api/services/projectService";
import { postAndStreamResponse } from "api/api-http-methods";
import InputWithSuggestions from "components/InputWithSuggestions";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import useSearchParamsState from "hooks/useSearchParamsState";
import { WORD_DOC_WITH_REFERENCE } from "api/services/fixtures";
import RecordsPlot from "components/chat-plots/RecordsPlot";
import TooltipNew from "components/ui/TooltipNew";
import ButtonIcon, { ButtonIconTransparent } from "components/ui/ButtonIcon";
import SourceFilesList from "components/SourceFilesList";
import TextAreaExpanding from "components/TextAreaExpanding";
import { sleep } from "utils/common";
import { postChatIngestToBoltzhb } from "api/services/chatService";
import { getDataIngestionJobUntilFinish } from "api/services/dataService";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { getSegments, renderSegments } from "pages/chat";
import Tooltip from "components/ui/Tooltip";
import { ArrowUpIcon, PersonBlankIcon } from "components/IconsNew";
import { BLOCKS_SIDE } from "components/side-chat-blocks";

const getBlockTextSegments = block => {
  // const block = cloneDeep(inputBlock);
  // if (block?.text?.startsWith(" ")) {
  //   block.text = block.text.slice(1);
  // }
  const blockTextSegments = [];
  let currentSegment = {
    text: "",
    meta: null,
    url: "",
    bgColor: "transparent",
  };
  let i = 0;
  while (i < block?.text?.length) {
    const styleAtIndex = block?.styles?.find(
      style => i >= style?.start && i < style?.end
    );
    const metaAtIndex = styleAtIndex?.meta || null;
    const urlAtIndex = styleAtIndex?.url || null;

    if (
      !isEqual(metaAtIndex, currentSegment?.meta) ||
      !isEqual(urlAtIndex, currentSegment?.url)
    ) {
      blockTextSegments.push(currentSegment);
      currentSegment = {
        text: "",
        url: urlAtIndex,
        meta: metaAtIndex,
        bgColor: styleAtIndex?.bgColor || "transparent",
        fontWeight: styleAtIndex?.fontWeight || "normal",
      };
    }

    currentSegment.text += block?.text?.[i];
    i++;
  }
  blockTextSegments.push(currentSegment);

  return blockTextSegments;
};

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr 60px;
  height: 100%;
`;

const TextBlocks = styled.div`
  margin-right: 12px;
  position: relative;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const InputArea = styled.div`
  position: relative;
  padding-bottom: 0px;
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const StyledInputWithSuggestions = styled.input`
  padding: 8px 16px;
  padding-right: 32px;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const BlockText = styled.div`
  position: relative;
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 14px;
  padding: 0 10px;

  ${props => props.isIngested && `background-color: #0191ff11;`}
  ${props => props.isHighlighted && `background-color: #f1f1f1;`}

  ${props => props.isDisabled && `pointer-events: none;`}
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const StyledFileCopy = styled(FileCopy)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const Actions = styled.div`
  padding: 8px 0;
  padding-left: 35px;
  display: flex;
  gap: 10px;
`;

const StyledBubbleSendIcon = styled(SendIcon)`
  opacity: 0;
  transition: opacity 0.2s;
  fill: #848484;
  height: 14px;
  margin-left: 4px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledTickIcon = styled(Done)`
  transition: opacity 0.2s;
  fill: #848484;
  height: 14px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }

  ${props => props.disabled && `opacity: 0.5; pointer-events: none;`}
`;

const InputAndSendButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 4px;
  justify-items: start;
  padding-bottom: 4px;

  :hover {
    ${StyledBubbleSendIcon} {
      opacity: 1;
    }
  }
`;

const StyledInput = styled(TextAreaExpanding)`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: max-content;
  resize: none;
  overflow: hidden;
  line-height: 1.4;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const ProfilePicContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
  svg {
    margin-top: 3px;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 14px;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 21px;

  /* position: fixed;
  z-index: 3;
  bottom: 34px;
  left: calc(50vw + 370px); */

  :hover {
    opacity: 0.5;
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableConainer = styled.div`
  padding-left: 45px;
  max-width: 100%;
  overflow: auto;
`;

const PulsingDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const StyledArrowDown = styled(KeyboardArrowDown)`
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  :hover {
    background-color: lightgrey;
  }
`;

const AttachBtn = styled(ButtonIconTransparent)`
  position: fixed;
  z-index: 3;
  bottom: 29px;
  height: 24px;
`;

const BlueDot = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
`;

const StyledBoltzbitLogo = styled(BoltzbitLogo)`
  fill: ${props => props.theme.color.closest};
  height: 28px;
`;

const CustomLogo = styled.div`
  fill: ${props => props.theme.color.closest};
  height: 28px;
  width: 28px;

  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const IndentedBlock = styled.div`
  position: relative;
  ${props =>
    props.hasListStyle &&
    `
  padding-left: 16px;
  ::before {
    position: absolute;
    left: 0px;
    top: -15px;
    font-size: 32px;
    content: "•";
  }
  `};
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  /* height: 14px; */
  margin-left: 4px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  fill: white;
`;

const QueryNonEdit = styled.div`
  display: grid;
  gap: 4px;
  padding-bottom: 12px;
  :hover {
    ${StyledPencilIcon} {
      opacity: 0.6;
    }
  }
`;

const GreyRect = styled.div`
  height: 14px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eaeaea;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const TickboxLabel = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: -16px;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 0px 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

const SuggestionsContainer = styled.div`
  border: 1px solid grey;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  padding: 6px 0px;
`;

const Suggestion = styled.div`
  padding: 8px;
  :hover {
    background-color: lightgrey;
  }

  padding: 8px 16px;
  padding-right: 32px;
`;

const SUGGESTION_TEXTS = ["Fill up this record", "Summarise this record"];

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const isListBlock = block => {
  return block?.text?.startsWith("* ");
};

const lightenColor = colorStr => {
  if (colorStr?.startsWith("#") && colorStr?.length === 9) {
    const coreColor = colorStr?.slice(0, 7);
    return `${coreColor}00`;
  }

  return colorStr;
};

const HUB_BASE_URL = "https://hub.boltzbit.com";

const ChatView = ({
  workingDocs = [],
  onChangeBlocks = newBlocks => {},
  onFocus = () => {},
  abortController = {},
  setAbortController = () => {},
  isGenerating = false,
  setIsGenerating = () => {},
  sigmaRecord = {},
  style = {},
  genContext = "word_query",
}) => {
  const [searchParams] = useSearchParams();

  const [userInput, setUserInput] = useState("");
  const [blocks, setBlocks] = useState([]);
  // const [isGenerating, setIsGenerating] = useState(false);
  // const [abortController, setAbortController] = useState(new AbortController());
  const [avatarSrc, setAvatarSrc] = useState("");
  const [logoSrc, setLogoSrc] = useState("");
  const [canSeeBottom, setCanSeeBottom] = useState(true);
  const [sources, setSources] = useSearchParamsState({
    paramName: "sources",
    initialValue: [],
  });

  const scrollerRef = useRef(null);

  const [pageResultsCache, setPageResultsCache] = useState({});

  const [highlightedQueryId, setHighlightedQueryId] = useState("");
  const [ingestingQueryId, setIngestingQueryId] = useState("");
  const [ingestedQueryIds, setIngestedQueryIds] = useState([]);

  const [editQueryId, setEditQueryId] = useState(null);
  const [editQueryText, setEditQueryText] = useState("");

  const [flowCube, setFlowCube] = useState(null);
  const [shouldSearchInternet, setShouldSearchInternet] = useState(false);

  const [isFocussed, setIsFocussed] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    doPopulateFlowDatasetId();
  }, []);

  const doPopulateFlowDatasetId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCube(data);
  };

  const doIngestDataPoint = async () => {
    setIngestingQueryId(highlightedQueryId);

    const ingestBody = {
      content: {
        blocks,
        sources,
      },
    };
    const { data: job } = await postChatIngestToBoltzhb(
      { queryId: highlightedQueryId },
      ingestBody
    );
    // await getDataIngestionJobUntilFinish(job?.dataIngestionJobId?.id);

    setIngestingQueryId("");
    setIngestedQueryIds([...ingestedQueryIds, highlightedQueryId]);
  };

  useEffect(() => {
    if (canSeeBottom) {
      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    }

    const newCanSeeBottom =
      scrollerRef?.current?.scrollTop + scrollerRef?.current?.clientHeight >=
      scrollerRef?.current?.scrollHeight - 20;
    setCanSeeBottom(newCanSeeBottom);
  }, [canSeeBottom, scrollerRef.current]);

  useEffect(() => {
    const initialQuery = searchParams?.get("initialQuery");
    if (!initialQuery) {
      return;
    }

    const newBlocks = [
      {
        isQuery: true,
        text: initialQuery,
        styles: [
          {
            isSelection: true,
            start: initialQuery?.length,
            end: initialQuery?.length,
          },
        ],
      },
    ];
    setBlocks(newBlocks);
    onPressEnterInCommandInput(initialQuery, newBlocks);
    // setBlocks(WORD_DOC_WITH_REFERENCE?.content?.blocks);
  }, [searchParams?.get("initialQuery")]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setAvatarSrc(data?.image || "");
    setLogoSrc(data?.organisation || "");
  };

  const onPressEnterInCommandInput = async (query, payloadBlocks) => {
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    const payload = {
      searchBarQuery: null,
      slashQuery: query,
      cursor: { blockIndex, letterIndex },
      genContext,
      blocks: payloadBlocks?.filter(block => !!block),
      sources,
      workingDocs,
      sigmaRecord,
      shouldSearchInternet,
    };

    const { error } = await postAndStreamResponse({
      // url: `${BASE_URL}/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed`,
      url: `${BASE_URL}/bz-api/v1/ai/streamed-chat-queries`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        setBlocks(data?.blocks || []);

        const newWorkingBlocks = data?.workingDocs?.[0]?.blocks || [];
        if (!newWorkingBlocks?.length) {
          return;
        }

        onChangeBlocks(newWorkingBlocks);
      },
    });

    setIsGenerating(false);
  };

  const doRerunBlock = async index => {
    const block = blocks?.[index];
    const blocksWithoutSelection = removeSelectionStyle(blocks);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(block?.text, blocksWithSelection);
    const blocksWithLoading = cloneDeep(blocks);
    blocksWithLoading[index + 1].isLoading = true;
    setBlocks(blocksWithLoading);
  };

  const onKeyDown = e => {
    e.stopPropagation();

    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      setIsFocussed(false);

      const blocksWithoutSelection = removeSelectionStyle(blocks);
      const payloadBlocks = [
        ...blocksWithoutSelection,
        {
          isQuery: true,
          text: userInput,
          styles: [
            {
              isSelection: true,
              start: userInput.length,
              end: userInput.length,
            },
          ],
        },
      ];
      setBlocks([
        ...payloadBlocks,
        { text: "...", isLoading: true, isThrowAway: true },
      ]);
      setUserInput("");
      onPressEnterInCommandInput(userInput, payloadBlocks);

      return;
    }
  };

  const inputRect = inputRef?.current?.getBoundingClientRect();
  let suggestionTexts = [];
  if (genContext === "record_page") {
    suggestionTexts = SUGGESTION_TEXTS;
  }

  const popoverStyle = {
    display: isFocussed && suggestionTexts?.length ? "block" : "none",
    position: "fixed",
    top: inputRect?.top,
    transform: "translateY(-100%)",
    width: 280,
    zIndex: 2,
  };

  return (
    <Container style={style}>
      <TextBlocks
        ref={scrollerRef}
        onScroll={e => {
          const newCanSeeBottom =
            e.target.scrollTop + e.target.clientHeight >=
            e.target.scrollHeight - 20;
          setCanSeeBottom(newCanSeeBottom);
        }}
      >
        <Gap height="20px" />
        {blocks?.map((block, index) => {
          const prevBlock = blocks?.[index - 1];
          const nextBlock = blocks?.[index + 1];

          if (block?.records && block?.columns) {
            return (
              <BlockText
                isHighlighted={highlightedQueryId === block?.queryId}
                isIngested={ingestedQueryIds.includes(block?.queryId)}
                isDisabled={ingestingQueryId === block?.queryId}
              >
                {(block?.isQuery || prevBlock?.isQuery) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      marginTop: "0px",
                      padding: "0px 0",
                      paddingTop: "0px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicContainer>
                      <BoltzhubLogoInner
                        style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                        height="14px"
                      />
                    </ProfilePicContainer>
                    {/* <BoldSpan>Assistant</BoldSpan> */}
                  </div>
                )}
                <div style={{ paddingLeft: "35px" }}>
                  <div>{block?.text}</div>
                  <RecordsPlot
                    genCode={block?.genCode}
                    records={block?.records}
                    columns={block?.columns}
                  />
                </div>
                {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                  <Actions>
                    <StyledReplay
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                      onClick={() => {
                        const queryBlockIndex = blocks
                          ?.slice(0, index)
                          ?.findLastIndex(qBlock => qBlock?.isQuery);
                        doRerunBlock(queryBlockIndex);
                      }}
                    />
                    <TooltipNew
                      tipContent={
                        <div
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            marginTop: "8px",
                            padding: "2px",
                          }}
                        >
                          {ingestedQueryIds.includes(prevBlock?.queryId)
                            ? "Verified"
                            : "Verify"}
                        </div>
                      }
                    >
                      {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                        <StyledTickIcon
                          style={{
                            fill: "#0191ff",
                            height: "16px",
                            width: "16px",
                          }}
                          onClick={() => {
                            const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                            window.open(url, "_blank");
                          }}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      ) : (
                        <StyledTickIcon
                          style={{ height: "16px", width: "16px" }}
                          onClick={doIngestDataPoint}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      )}
                    </TooltipNew>
                  </Actions>
                )}
              </BlockText>
            );
          }

          if (block?.isTableCell) {
            if (block?.rowIndex === 0 && block?.columnIndex === 0) {
              const numRows = block?.numberOfRows;
              const numCols = block?.numberOfColumns;
              const tableId = block?.tableId;

              return (
                <TableConainer>
                  <table>
                    <tbody>
                      {range(numRows).map(rowIndex => (
                        <tr key={`${tableId}-row-${rowIndex}`}>
                          {range(numCols).map(colIndex => {
                            const cellBlock = blocks?.find(
                              b =>
                                b?.tableId === tableId &&
                                b?.rowIndex === rowIndex &&
                                b?.columnIndex === colIndex
                            );

                            const segments = getSegments(cellBlock);

                            return (
                              <Td
                                key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                              >
                                {renderSegments(
                                  segments,
                                  cellBlock,
                                  isGenerating
                                )}
                                {/* {blockTextSegments?.map(segment => {
                                    if (segment?.meta) {
                                      return (
                                        <ReferenceModalSpan
                                          meta={segment?.meta}
                                          style={{
                                            backgroundColor: lightenColor(
                                              segment?.bgColor
                                            ),
                                            textDecoration: "underline",
                                            fontWeight: segment?.isBold
                                              ? 600
                                              : 400,
                                          }}
                                        >
                                          {segment?.text}
                                        </ReferenceModalSpan>
                                      );
                                    }
                                    return (
                                      <span
                                        style={{
                                          fontWeight: segment?.isBold
                                            ? 600
                                            : 400,
                                        }}
                                      >
                                        {segment?.text}
                                      </span>
                                    );
                                  })} */}
                              </Td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableConainer>
              );
            }
            return null;
          }

          if (block?.imageBase64) {
            return (
              <BlockText
                isDisabled={ingestingQueryId === block?.queryId}
                style={{
                  backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
                }}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    border: "1px solid #f1f1f1",
                  }}
                  src={`data:image/png;base64,${block?.imageBase64}`}
                  alt="plot"
                />
              </BlockText>
            );
          }

          if (block?.isLoading) {
            return (
              <BlockText>
                {(block?.isQuery || prevBlock?.isQuery) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      marginTop: "0px",
                      padding: "0px 0",
                      paddingTop: "0px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicContainer>
                      <BoltzhubLogoInner
                        style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                        height="14px"
                      />
                    </ProfilePicContainer>
                    {/* <BoldSpan>Assistant</BoldSpan> */}
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: "35px",
                    marginBottom: "5px",
                    display: "grid",
                    gap: "5px",
                  }}
                >
                  <GreyRect />
                  <GreyRect />
                  <GreyRect style={{ width: "50%" }} />
                </div>
              </BlockText>
            );
          }

          let editButtons = (
            <div
              style={{
                display: "flex",
                gap: "2px",
                marginTop: "4px",
                marginBottom: "2px",
                justifySelf: "end",

                // disable edit buttons for now
                opacity: 0,
                pointerEvents: "none",
              }}
            >
              <Tooltip
                title="Edit"
                isDisabled={editQueryId === block?.queryId || isGenerating}
              >
                <StyledBubbleSendButton
                  onClick={() => {
                    setEditQueryId(block?.queryId);
                    setEditQueryText(block?.text);
                  }}
                  disabled={editQueryId === block?.queryId || isGenerating}
                >
                  <StyledPencilIcon />
                </StyledBubbleSendButton>
              </Tooltip>

              <Tooltip
                title="Cancel"
                isDisabled={editQueryId !== block?.queryId}
              >
                <StyledBubbleSendButton
                  onClick={() => {
                    setEditQueryId(null);
                    setEditQueryText("");
                  }}
                  disabled={editQueryId !== block?.queryId}
                >
                  <CrossIcon style={{ fill: "white" }} />
                </StyledBubbleSendButton>
              </Tooltip>

              <Tooltip title="Send" isDisabled={editQueryId !== block?.queryId}>
                <StyledBubbleSendButton
                  onClick={() => {
                    const newBlocks = cloneDeep(blocks);
                    newBlocks[index].text = editQueryText;
                    setBlocks(newBlocks);
                    setEditQueryText("");
                    setEditQueryId(null);
                    doRerunBlock(index, [], newBlocks);
                  }}
                  disabled={editQueryId !== block?.queryId}
                >
                  <ArrowUpIcon height="14px" />
                </StyledBubbleSendButton>
              </Tooltip>
            </div>
          );

          let blockEditableContent = (
            <QueryNonEdit>
              <div style={{ padding: "2px 0" }} key={block?.text}>
                {block?.text}
              </div>
              {editButtons}
            </QueryNonEdit>
          );

          if (editQueryId === block?.queryId) {
            blockEditableContent = (
              <InputAndSendButton>
                <StyledInput
                  value={editQueryText}
                  onChange={e => setEditQueryText(e.target.value)}
                />
                {editButtons}
              </InputAndSendButton>
            );
          }

          const segments = getSegments(block);

          return (
            <BlockText
              isHighlighted={
                highlightedQueryId === block?.queryId && !block?.isQuery
              }
              isIngested={
                ingestedQueryIds.includes(block?.queryId) && !block?.isQuery
              }
              isDisabled={ingestingQueryId === block?.queryId}
            >
              {(block?.isQuery || prevBlock?.isQuery) && (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    marginTop: "0px",
                    padding: "0px 0",
                    paddingTop: "0px",
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicContainer>
                    {block?.isQuery &&
                      (avatarSrc ? (
                        <Img src={avatarSrc} />
                      ) : (
                        <PersonBlankIcon
                          style={{ marginTop: "0px" }}
                          height="14px"
                        />
                      ))}
                    {!block?.isQuery && (
                      <BoltzhubLogoInner
                        style={{ fill: COLOR2 }}
                        height="12px"
                      />
                    )}
                  </ProfilePicContainer>
                </div>
              )}
              <div style={{ paddingLeft: "35px" }}>
                {block?.isQuery && blockEditableContent}
                <IndentedBlock hasListStyle={isListBlock(block)}>
                  {!block?.isQuery &&
                    !block?.regenActions?.length &&
                    renderSegments(segments, block, isGenerating)}
                </IndentedBlock>
              </div>
              {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                <Actions>
                  <StyledReplay
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                    onClick={() => {
                      const queryBlockIndex = blocks
                        ?.slice(0, index)
                        ?.findLastIndex(qBlock => qBlock?.isQuery);
                      doRerunBlock(queryBlockIndex);
                    }}
                  />
                  <TooltipNew
                    tipContent={
                      <div
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "8px",
                          padding: "2px",
                        }}
                      >
                        {ingestedQueryIds.includes(prevBlock?.queryId)
                          ? "Verified"
                          : "Verify"}
                      </div>
                    }
                  >
                    {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                      <StyledTickIcon
                        style={{
                          fill: "#0191ff",
                          height: "16px",
                          width: "16px",
                        }}
                        onClick={() => {
                          const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                          window.open(url, "_blank");
                        }}
                        onMouseOver={() =>
                          setHighlightedQueryId(prevBlock?.queryId)
                        }
                        onMouseOut={() => setHighlightedQueryId("")}
                      />
                    ) : (
                      <StyledTickIcon
                        style={{ height: "16px", width: "16px" }}
                        onClick={doIngestDataPoint}
                        onMouseOver={() =>
                          setHighlightedQueryId(prevBlock?.queryId)
                        }
                        onMouseOut={() => setHighlightedQueryId("")}
                      />
                    )}
                  </TooltipNew>
                  <TooltipNew tipText="Copy">
                    <StyledFileCopy
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                      onClick={() => {
                        const queryBlocks = blocks?.filter(
                          b => b?.queryId === prevBlock?.queryId && !b?.isQuery
                        );
                        const queryStr = queryBlocks
                          ?.map(b => b?.text)
                          ?.join("\n");

                        navigator.clipboard.writeText(queryStr);
                      }}
                    />
                  </TooltipNew>
                </Actions>
              )}
            </BlockText>
          );
        })}
        <Gap height="20px" />
      </TextBlocks>

      <InputArea>
        {isGenerating && (
          <StyledCrossIcon
            onClick={() => {
              abortController.abort();
              setAbortController(new AbortController());
              setIsGenerating(false);
              const newBlocks = cloneDeep(blocks);
              const updatedBlocks = newBlocks
                ?.filter(block => !block?.isThrowAway)
                .map(block => {
                  block.isLoading = false;
                  return block;
                });
              setBlocks(updatedBlocks);
            }}
          />
        )}
        <StyledInputWithSuggestions
          ref={inputRef}
          disabled={isGenerating}
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          onFocus={() => {
            setIsFocussed(true);
            onFocus();
          }}
          onBlur={() => setIsFocussed(false)}
          placeholder="Ask a question"
          onKeyDown={onKeyDown}
        />
        <TickboxLabel>
          Search web?
          <input
            checked={shouldSearchInternet}
            onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
            type="checkbox"
          />
        </TickboxLabel>
        <SuggestionsContainer ref={suggestionsRef} style={popoverStyle}>
          <TickboxLabel
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShouldSearchInternet(!shouldSearchInternet);
            }}
            style={{
              position: "absolute",
              width: "max-content",
              top: -28,
              right: 4,
            }}
          >
            Search web?
            <input
              style={{ pointerEvents: "none" }}
              checked={shouldSearchInternet}
              onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
              type="checkbox"
            />
          </TickboxLabel>
          {suggestionTexts?.map(text => (
            <Suggestion
              onMouseDown={e => {
                e.preventDefault();
                setUserInput(text);
              }}
            >
              {text}
            </Suggestion>
          ))}
        </SuggestionsContainer>
      </InputArea>
    </Container>
  );
};

export default ChatView;
