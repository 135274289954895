import { ArrowUpIcon } from "components/IconsNew";
import { ChevronDownIcon, LogoInner } from "components/ui/Icons";
import { cloneDeep } from "lodash";
import { getTableContent, renderWithReferences } from "components/ChatViewNew";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const Container = styled.div`
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;

  display: grid;
  gap: 12px;
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  cursor: pointer;
  stroke: black;
  width: 16px;
  height: 16px;
  transform-origin: center;
  transform: ${props =>
    props?.isCollapsed ? "rotate(90deg)" : "rotate(0deg)"};
  z-index: 1;
  padding: 2px;
  margin-top: 6px;

  border-radius: 4px;
  :hover {
    background-color: #f1f1f1;
  }
`;

const TwoColumns = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const QueryText = styled.div`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 690px;
`;

const QueryType = styled.div`
  grid-column: span 3;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => props.bgColor}44;
  width: max-content;
  border-radius: 4px;
  padding: 0 2px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const QuestionBlockText = styled.textarea`
  border: none;
  resize: none;
  font-family: "Montserrat";
  position: relative;
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 4px;
  margin: 4px 0px;
  scroll-margin-top: 60px;
  border-left: 2px solid transparent;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${props => props.bgColor || "transparent"};
  width: 100%;
  height: 60px;
  border: 1px solid ${props => props.theme.color.primary};

  ${props =>
    props?.isOneLine &&
    `overflow: hidden; text-overflow: ellipsis; white-space: nowrap; height: 1.6em; pointer-events: none; border: 1px solid transparent;`}

  ${props => props.isIngested && `background-color: #0191ff11;`}
  ${props => props.isHighlighted && `background-color: #f1f1f1;`}

  ${props => props.isDisabled && `pointer-events: none;`}

  animation: none;
  @keyframes pulse {
    0% {
      border-left: 2px solid transparent;
    }
    50% {
      border-left: 2px solid #0191ff;
    }
    100% {
      border-left: 2px solid transparent;
    }
  }
`;

const BubbleTitle = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BlockContent = styled.div`
  white-space: pre-wrap;
`;

const AnswerBlocks = styled.div``;

const Step = ({ initialStep, onPressSend = newQuery => {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [step, setStep] = useState(initialStep);
  const [isExpanded, setIsExpanded] = useState(false);

  const bgColor = getColorFromString(`${step?.type}1`);

  useEffect(() => {
    setStep(initialStep);
  }, [JSON.stringify(initialStep)]);

  const highlightReferenceIdsStr = searchParams?.get("highlightReferenceIds");
  useEffect(() => {
    if (!highlightReferenceIdsStr) {
      return;
    }

    const hilightedReferenceIds = highlightReferenceIdsStr
      .split(",")
      ?.map(id => {
        const firstPart = id?.split("_")?.[0];
        return firstPart;
      });
    const ansBlockReferenceIds = step?.ansBlocks?.map(
      block => block?.referenceId
    );

    // if any highlighted reference id is present in the answer blocks
    if (hilightedReferenceIds.some(id => ansBlockReferenceIds.includes(id))) {
      setIsExpanded(true);
    }

    setSearchParams({});

    return () => setSearchParams({});
  }, [highlightReferenceIdsStr]);

  return (
    <div>
      <TwoColumns>
        <div>
          <QueryType bgColor={bgColor}>
            {step?.type} ({step?.ansBlocks?.length} p.)
          </QueryType>
          {isExpanded ? (
            <QuestionBlockText
              value={step?.query}
              onChange={e => setStep({ ...step, query: e.target.value })}
            />
          ) : (
            <QueryText>{step?.query}</QueryText>
          )}
        </div>
        <Buttons>
          <StyledChevronDownIcon
            isCollapsed={!isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <StyledBubbleSendButton
            disabled={!isExpanded}
            onClick={() => onPressSend(step?.query)}
          >
            <ArrowUpIcon />
          </StyledBubbleSendButton>
        </Buttons>
      </TwoColumns>
      <AnswerBlocks style={{ display: isExpanded ? "block" : "none" }}>
        {step?.ansBlocks?.map((block, index) => {
          const { isTableCell, rowIndex, columnIndex } = block;
          let dataRefId = "";
          if (!block?.isTableCell) {
            dataRefId = block?.referenceId;
          }

          if (isTableCell && (rowIndex !== 0 || columnIndex !== 0)) {
            return null;
          }

          let blockContent = renderWithReferences(block, setSearchParams);
          if (isTableCell) {
            blockContent = getTableContent(
              step?.ansBlocks,
              index,
              setSearchParams
            );
          }

          return (
            <BlockContent key={index} data-ref-id={dataRefId}>
              {blockContent}
            </BlockContent>
          );
        })}
      </AnswerBlocks>
    </div>
  );
};

const ChainOfThoughtSteps = ({ steps, onPressReRunStep = newSteps => {} }) => {
  if (!steps?.length || steps?.length === 1) {
    return null;
  }

  return (
    <Container>
      <BubbleTitle>
        <LogoInner />
        Chain of Thought
      </BubbleTitle>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            initialStep={step}
            onPressSend={newQuery => {
              const newSteps = cloneDeep(steps);
              newSteps[index].query = newQuery;
              onPressReRunStep(newSteps);
            }}
          />
        );
      })}
    </Container>
  );
};

export default ChainOfThoughtSteps;
