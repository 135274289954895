import styled from "styled-components";
import { useState, useEffect } from "react";
import { cloneDeep, last, range, uniq } from "lodash";
import { useSearchParams } from "react-router-dom";
import { FileCopy, Replay } from "@material-ui/icons";

import {
  BoltzhubLogoInner,
  CrossIcon,
  PencilIcon,
  RoundTickIcon,
} from "components/ui/Icons";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import { postAndStreamResponse } from "api/api-http-methods";
import TooltipNew from "components/ui/TooltipNew";
import TextAreaExpanding from "components/TextAreaExpanding";
import { sleep } from "utils/common";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import { ArrowUpIcon, PersonBlankIcon } from "components/IconsNew";
import SourcesCards from "components/SourcesCards";
import ReferenceModalSpanWithContext, {
  getMetaId,
} from "components/ReferenceModalSpanWithContext";
import InputWithBottomSuggestionsAndSources from "components/InputWithBottomSuggestionsAndSources";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { GreyText } from "pages/chat";
import InputExpanding from "components/InputExpanding";
import ChainOfThoughtSteps from "components/ChainOfThoughtSteps";
import { useRef } from "react";
import ChatTablePlotModal from "components/ChatTablePlotModal";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding-top: 0px;
  height: 100%;
  grid-template-rows: 1fr ${props => (props?.isSmallInput ? 40 : 73)}px;
  transition: width 0.2s;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledRecoInput = styled(InputWithBottomSuggestionsAndSources)`
  width: 800px;
  padding: 12px 32px;
  padding-right: 80px;
  padding-left: 48px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
  z-index: 1;
  max-height: 200px;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const FadeRect = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  background: linear-gradient(transparent, white);
  z-index: 1;
  pointer-events: none;
  bottom: calc(20px + 53px);
`;

const ProfilePicContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
  svg {
    margin-top: 3px;
  }
`;

/*
{
    "columnIndex": 0,
    "columns": null,
    "isQuery": false,
    "isTableCell": true,
    "numberOfColumns": 2,
    "numberOfRows": 4,
    "queryId": "672a41e2950400177f6bec2d",
    "records": null,
    "referenceId": "d79ddfb0-89f9-4f97-9b85-b1922d23a494",
    "regenActions": null,
    "rowIndex": 3,
    "sourceCodes": null,
    "styles": [
        {
            "citations": null,
            "end": 3,
            "metas": [
                {
                    "blocksReferenceId": "4d82c247-3aab-48e0-b1c5-d0400ba8ffbf",
                    "fileId": "",
                    "fileType": "IN_DOC",
                    "llmPromptReplies": null,
                    "referenceUrls": null,
                    "references": null
                }
            ],
            "start": 2,
            "value": " "
        }
    ],
    "tableId": "4930036d-fa57-4c63-bff9-e46fdf6e980b",
    "text": " Man Group (Q4 2024 Credit Outlook)  "
}
*/
const CrossContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 18px;
  cursor: pointer;
  z-index: 2;
`;

const Blocks = styled.div`
  margin-top: 50px;
  padding-bottom: 50px;
  overflow-y: auto;
`;

const TwoColumns = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 10px;
  margin: 0;

  animation: none;
  border-radius: 2px;
  @keyframes pulse {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #0191ff22;
    }
    100% {
      background-color: transparent;
    }
  }
`;

const RightContent = styled.div`
  overflow: auto;
  grid-column: 2;
  line-height: 1.5;
`;

const BlockContent = styled.div`
  white-space: pre-wrap;
`;

const Actions = styled.div`
  min-height: 24px;
  margin-bottom: 16px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
  margin-top: 4px;
`;

const StyledRoundTickIcon = styled(RoundTickIcon)`
  cursor: pointer;
  margin-left: 2px;
  fill: #00c85355;
  :hover {
    fill: #0191ff;
  }
`;

const GreyRect = styled.div`
  height: 14px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eaeaea;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const Table = styled.table`
  width: max-content;
`;

const TableContainer = styled.div`
  position: relative;
  padding-left: 0px;
  width: 100%;
  overflow: auto;
  margin: 12px 0;
`;

const StyledInput = styled(TextAreaExpanding)`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: max-content;
  max-height: 200px;
  resize: none;
  line-height: 1.4;
  border: 1px solid #c7c7c7;
  :disabled {
    color: black;
  }
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  fill: white;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 4px;
  margin-bottom: 2px;
  justify-self: end;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const StyledInputExpanding = styled(InputExpanding)`
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
`;

const TopLeftBar = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledFileCopy = styled(FileCopy)`
  opacity: 0.5;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
  :hover {
    opacity: 1;
  }
`;

const StyledArrowUpIcon = styled(ArrowUpIcon)`
  background-color: #ff9561;
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
  width: 12px;
  height: 12px;
  stroke: black !important;
  :hover {
    opacity: 0.6;
  }
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
  :hover {
    opacity: 1;
  }
`;

const InputArea = styled.div`
  position: relative;
  padding-bottom: 0px;
  background-color: ${props => props.theme.color.furthest};
  padding: 0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const StyledSmallInput = styled.input`
  padding: 8px 16px;
  padding-right: 32px;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 14px;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 10px;

  :hover {
    opacity: 0.5;
  }
`;

const TickboxLabel = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: -22px;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 0px 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

const SuggestionsContainer = styled.div`
  border: 1px solid grey;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  padding: 6px 0px;
`;

const Suggestion = styled.div`
  padding: 8px;
  :hover {
    background-color: lightgrey;
  }

  padding: 8px 16px;
  padding-right: 32px;
`;

const StyledLink = styled.a`
  margin: 0 2px;
`;

const BlockLoadingState = () => (
  <div style={{ display: "grid", gap: "5px", marginBottom: 5 }}>
    <GreyRect />
    <GreyRect />
    <GreyRect style={{ width: "50%" }} />
  </div>
);

const BOT_PIC = (
  <ProfilePicContainer>
    <BoltzhubLogoInner
      style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
      height="12px"
    />
  </ProfilePicContainer>
);

const getProfilePic = (blocks, index, userProfile = {}) => {
  const block = blocks?.[index];
  const prevBlock = blocks?.[index - 1];

  let leftContent = <div />;
  if (!block?.isQuery && prevBlock?.isQuery) {
    leftContent = BOT_PIC;
  }

  if (block?.isQuery && !prevBlock?.isQuery) {
    leftContent = (
      <ProfilePicContainer>
        {userProfile?.image ? (
          <Img src={userProfile?.image} />
        ) : (
          <PersonBlankIcon style={{ marginTop: "0px" }} height="14px" />
        )}
      </ProfilePicContainer>
    );
  }

  return leftContent;
};

const getStyleWithMetaIds = style => {
  const newStyle = cloneDeep(style);
  if (!style?.metas) {
    return newStyle;
  }

  newStyle.metas = newStyle.metas.map(meta => {
    return { ...meta, value: style?.value, id: getMetaId(meta, style?.value) };
  });

  return newStyle;
};

export const scrollToBlocks = async (
  blockReferenceIds,
  setSearchParams = () => {}
) => {
  setSearchParams({ highlightReferenceIds: blockReferenceIds?.join(",") });

  // allow time for chain of thought step to expand
  await sleep(100);

  blockReferenceIds?.forEach(blockRefId => {
    const blockElements = document.querySelectorAll(
      `[data-ref-id="${blockRefId}"]`
    );

    blockElements?.forEach(blockElement => {
      blockElement.style.setProperty("animation", "1s pulse 2");
      setTimeout(() => {
        blockElement.style.removeProperty("animation");
      }, 2000);
    });
  });

  const firstBlockElement = document.querySelector(
    `[data-ref-id="${blockReferenceIds?.[0]}"]`
  );
  firstBlockElement.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

const getUrlToLabelMap = block => {
  let allUrls = [];

  block?.styles?.forEach(style => {
    style?.metas?.forEach(meta => {
      if (meta?.referenceUrls) {
        allUrls = [...allUrls, ...meta?.referenceUrls];
      }
    });
  });

  const uniqueUrls = uniq(allUrls);

  let urlToLabelMap = {};
  uniqueUrls?.forEach((url, index) => {
    urlToLabelMap[url] = `[${index + 1}]`;
  });

  return urlToLabelMap;
};

const addMdStyling = text => {
  if (!text) {
    return text;
  }

  // bold
  let styledText = text.replace(/\*\*/g, (match, offset, string) => {
    let count = (string.slice(0, offset).match(/\*\*/g) || []).length;
    return count % 2 === 0 ? "<strong>" : "</strong>";
  });

  // titles
  styledText = styledText.replace(
    /^#+\s*(.+)$/gm,
    (match, titleContent) =>
      `<span style="font-size: 20px; font-weight: 600">${titleContent}</span>`
  );

  return styledText;
};

export const renderWithReferences = (block, setSearchParams = () => {}) => {
  let segments = [{ text: "" }];

  let i = 0;
  let isRef = false;
  while (i <= block?.text?.length) {
    const refSpan = block?.referenceSpans?.find(
      ([start, end]) => i >= start && i <= end
    );
    if (refSpan && !isRef) {
      isRef = true;
      let spanId = `${block?.referenceId}_${refSpan?.[0]}_${refSpan?.[1]}`;
      last(segments).text += `<span data-ref-id=${spanId}>`;
    }
    if (!refSpan && isRef) {
      isRef = false;
      last(segments).text += `</span>`;
    }

    const table = block?.tables?.find(
      table => i >= table?.start && i <= table?.end
    );
    if (table) {
      if (i === table?.start) {
        segments.push({ text: "", tableBlocks: table?.blocks });
      }
      if (i === table?.end) {
        segments.push({ text: "" });
      }
      i++;
      continue;
    }

    const styleEndingHere = block?.styles?.find(style => style?.end === i);
    if (styleEndingHere) {
      segments.push({ style: getStyleWithMetaIds(styleEndingHere) });
      segments.push({ text: "" });
    }

    last(segments).text += block?.text[i] || "";
    i++;
  }

  // add md styles
  segments = segments.map(segment => ({
    ...segment,
    text: addMdStyling(segment?.text),
  }));

  const urlToLabelMap = getUrlToLabelMap(block);

  return segments?.map((segment, index) => {
    const metas = segment?.style?.metas;
    const inDocMetas = metas?.filter(meta => meta?.fileType === "IN_DOC");
    const fileMetas = metas?.filter(meta => meta?.fileType !== "IN_DOC");

    let inDocMetasArrow = null;
    if (inDocMetas?.length) {
      const blockIds = inDocMetas?.map(meta => {
        const { blocksReferenceId, blockStart, blockEnd } = meta;
        return `${blocksReferenceId}_${blockStart}_${blockEnd}`;
      });
      inDocMetasArrow = (
        <StyledArrowUpIcon
          onClick={() => {
            scrollToBlocks(blockIds, setSearchParams);
          }}
        />
      );
    }

    if (metas?.[0]?.referenceUrls && metas?.length === 1) {
      return metas?.[0]?.referenceUrls?.map((url, index) => (
        <StyledLink href={url} target="_blank">
          {urlToLabelMap?.[url] || "(link)"}
        </StyledLink>
      ));
    }

    let fileMetasTick = null;
    if (fileMetas?.length) {
      fileMetasTick = (
        <ReferenceModalSpanWithContext
          isTooltipDisabled
          key={index}
          blocks={[block]}
          clickedMetaId={fileMetas?.[0]?.id}
          metas={fileMetas}
        >
          <StyledRoundTickIcon height="12px" />
        </ReferenceModalSpanWithContext>
      );
    }

    if (fileMetasTick || inDocMetasArrow) {
      return (
        <>
          {inDocMetasArrow}
          {fileMetasTick}
        </>
      );
    }

    if (segment?.tableBlocks) {
      return getTableContent(segment?.tableBlocks, 0, setSearchParams);
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: segment?.text,
        }}
      />
    );
  });
};

export const getTableContent = (blocks, index, setSearchParams = () => {}) => {
  const block = blocks?.[index];
  const numRows = block?.numberOfRows;
  const numCols = block?.numberOfColumns;
  const tableId = block?.tableId;

  return (
    <TableContainer>
      <Table>
        <tbody>
          {range(numRows).map(rowIndex => (
            <tr key={`${tableId}-row-${rowIndex}`}>
              {range(numCols).map(colIndex => {
                const cellBlock = blocks?.find(
                  b =>
                    b?.tableId === tableId &&
                    b?.rowIndex === rowIndex &&
                    b?.columnIndex === colIndex
                );

                return (
                  <Td
                    key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                    data-ref-id={`${cellBlock?.referenceId || ""}`}
                  >
                    {renderWithReferences(cellBlock, setSearchParams)}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TERMS_LINKS = (
  <GreyText>
    The model can make mistakes. Check important info.&nbsp;
    <a href="/terms" target="_blank">
      Terms of Services
    </a>
    &nbsp;and&nbsp;
    <a href="/privacy-policy" target="_blank">
      Privacy Policy
    </a>
  </GreyText>
);

const SUGGESTION_TEXTS = ["Fill up this record", "Summarise this record"];

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}
const CHAT_QUERIES_URL = "/bz-api/v1/ai/streamed-chat-queries";

const ChatViewNew = ({
  wordDocId = "",
  isSmallInput = false,
  genContext = "",
  sigmaRecord = null,
  workingDocs = [],
  pipelineConfigId = "",
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userInput, setUserInput] = useState("");
  const [sources, setSources] = useState([]);
  const [userSources, setUserSources] = useState([]);

  const [wordDoc, setWordDoc] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [promptRecommendations, setPromptRecommendations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const [editQueryId, setEditQueryId] = useState(null);
  const [editQueryText, setEditQueryText] = useState("");

  const [shouldSearchInternet, setShouldSearchInternet] = useState(false);
  const [shouldUseFiles, setShouldUseFiles] = useState(true);
  const [shouldUsePipeline, setShouldUsePipeline] = useState(
    pipelineConfigId ? true : false
  );
  const [userProfile, setUserProfile] = useState({});

  const inputRef = useRef();
  const suggestionsRef = useRef();
  const [isFocussed, setIsFocussed] = useState(false);

  const initialShouldSearchInternet = searchParams?.get("shouldSearchInternet");
  useEffect(() => {
    if (initialShouldSearchInternet === "true") {
      setShouldSearchInternet(true);
    }
  }, [searchParams?.get("shouldSearchInternet")]);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  useEffect(() => {
    setUserSources(sources);
  }, [JSON.stringify(sources)]);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
  };

  useEffect(() => {
    if (isGenerating || !blocks?.length || !wordDocId) {
      return;
    }
    patchWordDoc(wordDocId, {}, { content: { blocks, sources } });
  }, [isGenerating]);

  const doPopulateWordDoc = async () => {
    if (!wordDocId) {
      return;
    }
    const { data, error } = await getWordDoc(wordDocId);
    if (error) {
      return;
    }
    setWordDoc(data);
    let newBlocks = data?.content?.blocks;
    setSources(data?.content?.sources || []);
    const newUserSources =
      last(newBlocks?.filter(b => b?.isQuery))?.userSources || [];
    setUserSources(newUserSources || []);

    if (!last(newBlocks)?.isQuery) {
      setBlocks(newBlocks);
      return;
    }

    setBlocks([...newBlocks, { text: "", isLoading: true, isThrowAway: true }]);
    onPressEnterInCommandInput(newBlocks, newUserSources || []);
  };

  const stopGeneration = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGenerating(false);
    const newBlocks = cloneDeep(blocks);
    const updatedBlocks = newBlocks
      ?.filter(block => !block?.isThrowAway)
      .map(block => {
        block.isLoading = false;
        return block;
      });
    setBlocks(updatedBlocks);
  };

  const onPressEnterInCommandInput = async (
    payloadBlocks,
    payloadSources = []
  ) => {
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    let sourcesToSend = userSources;
    if (payloadSources?.length > 0) {
      sourcesToSend = payloadSources;
    }
    if (!shouldUseFiles) {
      sourcesToSend = [];
    }
    if (shouldUsePipeline) {
      sourcesToSend = [
        ...sourcesToSend,
        { type: "PIPELINE", fileId: pipelineConfigId },
      ];
    }

    let payloadShouldSearchInternet = shouldSearchInternet;
    if (initialShouldSearchInternet === "true") {
      payloadShouldSearchInternet = true;
      setSearchParams({});
    }
    const payload = {
      cursor: { blockIndex, letterIndex },
      shouldSearchInternet: payloadShouldSearchInternet,
      genContext: "word_query",
      blocks: payloadBlocks?.filter(block => !!block),
      sources: sourcesToSend,
      sigmaRecord,
      workingDocs,
    };

    console.log({ payload });
    await postAndStreamResponse({
      url: `${BASE_URL}${CHAT_QUERIES_URL}`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        setBlocks(data?.blocks || []);
        setPromptRecommendations(data?.promptRecommendations || []);
      },
    });

    setIsGenerating(false);
  };

  const onKeyDown = e => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const blocksWithoutSelection = removeSelectionStyle(blocks);
      const payloadBlocks = [
        ...blocksWithoutSelection,
        {
          isQuery: true,
          text: userInput,
          userSources,
          styles: [
            {
              isSelection: true,
              start: userInput.length,
              end: userInput.length,
            },
          ],
        },
      ];
      setBlocks([
        ...payloadBlocks,
        { text: "", isLoading: true, isThrowAway: true },
      ]);
      setUserInput("");
      onPressEnterInCommandInput(payloadBlocks);

      return;
    }
  };

  const doRerunBlock = async (index, blocksToUse = [], wipeSteps = false) => {
    if (blocksToUse?.length === 0) {
      blocksToUse = blocks;
    }
    const blocksToUseCopy = cloneDeep(blocksToUse);

    const block = blocksToUseCopy?.[index];
    if (wipeSteps && block?.chainOfThought) {
      block.chainOfThought = null;
    }
    const blocksWithoutSelection = removeSelectionStyle(blocksToUseCopy);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(blocksWithSelection, block?.userSources || []);

    let blocksWithLoading = cloneDeep(blocksToUse);
    blocksWithLoading[index + 1].isLoading = true;
    blocksWithLoading = blocksWithLoading.filter(
      oldBlock =>
        oldBlock?.isLoading ||
        oldBlock?.queryId !== block?.queryId ||
        oldBlock?.isQuery
    );
    setBlocks(blocksWithLoading);
  };

  const getActions = (blocks, index) => {
    const block = blocks?.[index];
    if (block?.isQuery) {
      const onClickSend = () => {
        const newBlocks = cloneDeep(blocks);
        newBlocks[index].text = editQueryText;
        setBlocks(newBlocks);
        setEditQueryText("");
        setEditQueryId(null);
        doRerunBlock(index, newBlocks, true);
      };

      let editButtons = (
        <EditButtons>
          {editQueryId !== block?.queryId && (
            <StyledBubbleSendButton
              onClick={() => {
                setEditQueryId(block?.queryId);
                setEditQueryText(block?.text);
              }}
              disabled={editQueryId === block?.queryId}
            >
              <StyledPencilIcon />
            </StyledBubbleSendButton>
          )}

          {editQueryId === block?.queryId && (
            <StyledBubbleSendButton
              onClick={() => {
                setEditQueryId(null);
                setEditQueryText("");
              }}
              disabled={editQueryId !== block?.queryId}
            >
              <CrossIcon style={{ fill: "white" }} />
            </StyledBubbleSendButton>
          )}

          <StyledBubbleSendButton
            onClick={onClickSend}
            disabled={editQueryId !== block?.queryId}
          >
            <ArrowUpIcon height="14px" />
          </StyledBubbleSendButton>
        </EditButtons>
      );

      return <Actions style={{ justifyContent: "end" }}>{editButtons}</Actions>;
    }

    const prevBlock = blocks?.[index - 1];
    const nextBlock = blocks?.[index + 1];

    const copyBlocksContent = () => {
      const queryBlocks = blocks?.filter(
        b => b?.queryId === prevBlock?.queryId && !b?.isQuery
      );
      const queryStr = queryBlocks?.map(b => b?.text)?.join("\n");

      navigator.clipboard.writeText(queryStr);
    };

    const rerunQuery = () => {
      const queryBlockIndex = blocks
        ?.slice(0, index)
        ?.findLastIndex(qBlock => qBlock?.isQuery);
      doRerunBlock(queryBlockIndex, blocks, true);
    };

    if (nextBlock?.isQuery !== block?.isQuery) {
      return (
        <Actions>
          <TooltipNew tipText="Re-generate">
            <StyledReplay onClick={rerunQuery} />
          </TooltipNew>
          <TooltipNew tipText="Copy">
            <StyledFileCopy onClick={copyBlocksContent} />
          </TooltipNew>
        </Actions>
      );
    }

    return null;
  };

  let suggestionTexts = [];
  if (genContext === "record_page") {
    suggestionTexts = SUGGESTION_TEXTS;
  }

  const inputRect = inputRef?.current?.getBoundingClientRect();
  const popoverStyle = {
    display:
      isFocussed && !isGenerating && suggestionTexts?.length ? "block" : "none",
    position: "fixed",
    top: inputRect?.top,
    transform: "translateY(-100%)",
    width: 280,
    zIndex: 2,
  };

  let inputElement = (
    <InputContainer>
      {isGenerating && (
        <CrossContainer onClick={stopGeneration}>
          <CrossIcon />
        </CrossContainer>
      )}
      <StyledRecoInput
        blocks={blocks}
        id="input"
        placeholder={"Ask a question"}
        value={userInput}
        sources={sources}
        setSources={setSources}
        onChange={e => setUserInput(e.target.value)}
        onClickSuggestion={text => setUserInput(text)}
        onKeyDown={onKeyDown}
        userSources={userSources}
        setUserSources={setUserSources}
        externalSuggestions={promptRecommendations}
        shouldSearchInternet={shouldSearchInternet}
        setShouldSearchInternet={setShouldSearchInternet}
        shouldUseFiles={shouldUseFiles}
        setShouldUseFiles={setShouldUseFiles}
        shouldUsePipeline={shouldUsePipeline}
        setShouldUsePipeline={setShouldUsePipeline}
        canUseDb={pipelineConfigId}
      />
      {TERMS_LINKS}
    </InputContainer>
  );
  if (isSmallInput) {
    inputElement = (
      <InputArea>
        {isGenerating && (
          <StyledCrossIcon
            onClick={() => {
              abortController.abort();
              setAbortController(new AbortController());
              setIsGenerating(false);
              const newBlocks = cloneDeep(blocks);
              const updatedBlocks = newBlocks
                ?.filter(block => !block?.isThrowAway)
                .map(block => {
                  block.isLoading = false;
                  return block;
                });
              setBlocks(updatedBlocks);
            }}
          />
        )}
        <StyledSmallInput
          ref={inputRef}
          disabled={isGenerating}
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          onFocus={() => {
            setIsFocussed(true);
          }}
          onBlur={() => setIsFocussed(false)}
          placeholder="Ask a question"
          onKeyDown={onKeyDown}
        />
        <TickboxLabel>
          Search web?
          <input
            checked={shouldSearchInternet}
            onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
            type="checkbox"
          />
        </TickboxLabel>
        <SuggestionsContainer ref={suggestionsRef} style={popoverStyle}>
          <TickboxLabel
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShouldSearchInternet(!shouldSearchInternet);
            }}
            style={{
              position: "absolute",
              width: "max-content",
              top: -28,
              right: 4,
            }}
          >
            Search web?
            <input
              style={{ pointerEvents: "none" }}
              checked={shouldSearchInternet}
              onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
              type="checkbox"
            />
          </TickboxLabel>
          {suggestionTexts?.map(text => (
            <Suggestion
              onMouseDown={e => {
                e.preventDefault();
                setUserInput(text);
              }}
            >
              {text}
            </Suggestion>
          ))}
        </SuggestionsContainer>
      </InputArea>
    );
  }

  return (
    <Container isSmallInput={isSmallInput}>
      <Blocks>
        {blocks?.map((block, index) => {
          if (block?.isQuery) {
            let queryContent = block?.text;
            if (editQueryId === block?.queryId) {
              queryContent = (
                <StyledInput
                  value={editQueryText}
                  onChange={e => setEditQueryText(e.target.value)}
                />
              );
            }

            let cotBubble = null;
            if (!last(blocks)?.isLoading && block?.chainOfThought?.steps) {
              const onPressReRunStep = newSteps => {
                const newBlocks = cloneDeep(blocks);
                newBlocks[index].chainOfThought.steps = newSteps;
                setBlocks(newBlocks);
                doRerunBlock(index, newBlocks);
              };

              cotBubble = (
                <TwoColumns
                  key={`${index}-cot`}
                  data-ref-id={block?.referenceId}
                >
                  {BOT_PIC}
                  <RightContent>
                    <ChainOfThoughtSteps
                      steps={block?.chainOfThought?.steps}
                      onPressReRunStep={onPressReRunStep}
                    />
                  </RightContent>
                </TwoColumns>
              );
            }

            return (
              <>
                <TwoColumns key={index} data-ref-id={block?.referenceId}>
                  {getProfilePic(blocks, index, userProfile)}
                  <RightContent>
                    <SourcesCards
                      sourceOptions={block?.userSources}
                      userSources={block?.userSources}
                      isEditingDisabled
                      isInitiallyOpen
                      expandedHeight="auto"
                    />
                    <BlockContent>{queryContent}</BlockContent>
                    {getActions(blocks, index)}
                  </RightContent>
                </TwoColumns>
                {cotBubble}
              </>
            );
          }

          if (block?.records) {
            return (
              <ChatTablePlotModal
                block={block}
                blocks={blocks}
                isGenerating={isGenerating}
              />
            );
          }

          let blockContent = renderWithReferences(block, setSearchParams);
          if (block?.isLoading) {
            blockContent = <BlockLoadingState />;
          }
          let dataRefId = block?.referenceId || "";

          return (
            <TwoColumns key={index} data-ref-id={dataRefId}>
              {getProfilePic(blocks, index)}
              <RightContent>
                <BlockContent>{blockContent}</BlockContent>
                {getActions(blocks, index)}
              </RightContent>
            </TwoColumns>
          );
        })}
      </Blocks>
      {!isSmallInput && <FadeRect />}
      {inputElement}
    </Container>
  );
};

export default ChatViewNew;
