import { useEffect, useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { getFile, getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ButtonWord from "components/ui/ButtonWord";
import {
  CrossIcon,
  FilesIcon,
  PdfIcon,
  WarningIcon,
} from "components/ui/Icons";
import { groupBy, last, uniq, uniqBy } from "lodash";
import { EmailContent } from "components/FieldReferenceModal";
import { getColorFromString, safeFormat } from "utils/common";
import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";

export const useIsDebug = (initialValue = false) => {
  const [isDebug, setIsDebug] = useState(initialValue);

  useEffect(() => {
    const toggleDebug = e => {
      if (e.key === "D" && e.shiftKey) {
        setIsDebug(prev => !prev);
      }
    };

    document.addEventListener("keydown", toggleDebug);
    return () => document.removeEventListener("keydown", toggleDebug);
  }, []);

  return isDebug;
};

export const getMetaId = (meta, value = "") => {
  const refCoords = meta?.references
    ?.map(ref => `${ref?.x}-${ref?.y}`)
    ?.join("-");
  return `${meta?.fileId}-${meta?.pageNumber}-${refCoords}-${value}`;
};

const Span = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  display: flex;
  align-items: center;
  gap: 2px;
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none; 
    opacity: 0.6;
    svg {
      fill: #9a9a9a;
    }  
  `}
`;

const ModalContent = styled.div`
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 24px;
  background: linear-gradient(180deg, #f3f5f7 0%, #f3f5f7 100%);
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 560px;

  align-content: startInd;
  white-space: pre-wrap;
  overflow: auto;

  @media (max-width: 1240px) {
    width: 90vw;
    height: auto;
    font-size: 14px;
    padding: 20px;
    line-height: 1.2;
  }
`;

const FileLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  background-color: white;
  padding: 8px;
`;

const Tabs = styled.div`
  display: flex;
  overflow: auto;
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props?.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer0};
  ${props => props.isSelected && `color: ${props.theme.color.primary};`}
`;

const ShortDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 200px;
`;

const TitleContainer = styled.div`
  background-color: white;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 20px;
  max-height: 60px;
`;

const ImgWithRectangleFlexibleStyled = styled(ImgWithRectangleFlexible)``;

const DocContainer = styled.div`
  padding: 40px;
  height: 500px;
  overflow: auto;
  background-color: #f3f5f7;
  border: 1px solid #f3f5f7;
  border-radius: 0;
  position: relative;
`;

const ZoomButtons = styled.div`
  position: fixed;
  z-index: 10000;
  transform: translate(-35px, -35px);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  width: 585px;
  gap: 4px;
`;

const UploadedText = styled.div`
  font-size: 12px;
  background-color: #ebebeb;
  justify-self: endInd;
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid #bbbbbb;
`;

const ZoomButton = styled(ButtonWord)`
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DocAnchor = styled.a`
  justify-self: endInd;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const NoFileText = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
  opacity: 0.6;
  justify-content: center;
  justify-items: center;
`;

const FilesCount = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${props => props.theme.color.primary};
`;

const EmailSubject = styled.div`
  position: relative;
  padding: 8px 20px;
  white-space: pre-wrap;
  line-height: 1.2;
  background-color: white;
  border-top: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  max-height: 200px;
  overflow: auto;
  font-weight: 500;
`;

const SpansContainer = styled.div`
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  color: transparent;
  width: 100%;
`;

const LabelTip = styled.div`
  padding: 2px 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  svg {
    fill: white;
    height: 12px;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #ccc;
`;

const R = {
  id: "1",
  rawText:
    "Hello this is a raw text\n\nHello this is a raw text. Hello this is a raw text. Hello this is a raw text. Hello this is a raw text",
  subject: "This is a subject",
};

const renderSegments = textSegments => {
  return textSegments.map((segment, i) => {
    if (segment?.label) {
      return (
        <SpanWithHoverTooltip
          key={`${segment?.label}-${i}-${segment?.text}`}
          tooltip={segment?.tooltip}
          style={segment?.style}
        >
          {segment?.text}
        </SpanWithHoverTooltip>
      );
    }
    return segment?.text;
  });
};

const getRefId = reference =>
  `${reference?.fileId}-${reference?.pageNumber}-${
    reference?.rawText?.slice(0, 200) || ""
  }`;

const RecordFilesModal = ({
  className = "",
  children,
  isDisabled = false,
  record = null,
  columns = {},
}) => {
  const [isOpen, setOpen] = useState(false);
  const [pageResults, setPageResults] = useState(null);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [selectedRefId, setSelectedRefId] = useState("");
  const [file, setFile] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [spans, setSpans] = useState([]);

  const allReferences = Object.entries(record?.fields || {}).map(
    ([fieldKey, fieldValue]) => ({
      id: getRefId(fieldValue?.reference),
      ...fieldValue?.reference,
      label: `${fieldKey} (AI)`,
    })
  );
  let uniqueReferences = uniqBy(allReferences, ref => getRefId(ref))?.filter(
    ref => ref?.fileId || ref?.rawText
  );

  useEffect(() => {
    setSpans(allReferences || []);
  }, [JSON.stringify(allReferences)]);

  const selectedMeta = uniqueReferences?.find(ref => ref?.id === selectedRefId);

  const removeSpan = (startInd, endInd, inSubject) => {
    let removalId = `${startInd}-${endInd}-${inSubject}`;
    let newSpans = spans.filter(span => {
      return (
        `${span?.startInd}-${span?.endInd}-${span?.inSubject}` !== removalId
      );
    });
    setSpans(newSpans);
  };

  const getSegments = (text, spansToUse) => {
    let outputSegments = [{ text: "", label: "" }];
    let i = 0;
    while (i < text?.length) {
      let spanStart = spansToUse
        ?.filter(s => s?.id === selectedRefId)
        ?.find(span => i === span.startInd);
      let spanEnd = spansToUse
        ?.filter(s => s?.id === selectedRefId)
        ?.find(span => i === span.endInd);

      if (spanStart) {
        let tooltip = (
          <LabelTip>
            <FilesIcon
              onClick={() =>
                navigator.clipboard.writeText(
                  text?.slice(spanStart?.startInd, spanStart?.endInd + 1)
                )
              }
            />
            {spanStart?.label?.replace("_", " / ")}
            <CrossIcon
              onClick={() =>
                removeSpan(
                  spanStart?.startInd,
                  spanStart?.endInd,
                  spanStart?.inSubject
                )
              }
            />
          </LabelTip>
        );
        if (spanStart?.label === "TBD") {
          tooltip = null;
        }

        outputSegments.push({
          text: "",
          label: spanStart?.label,
          style: {
            backgroundColor: `${getColorFromString(spanStart?.label)}44`,
          },
          tooltip,
        });
      }

      last(outputSegments).text += text[i];

      if (spanEnd) {
        outputSegments.push({ text: "", label: "" });
      }

      i++;
    }

    return outputSegments;
  };

  const textSegments = getSegments(
    selectedMeta?.rawText,
    spans?.filter(s => !s?.inSubject)
  );
  const subjectSegments = getSegments(
    selectedMeta?.subject,
    spans?.filter(s => s?.inSubject)
  );

  const onMouseUp = ({ inSubject = false }) => {
    setIsMouseDown(false);
    const sel = window.getSelection();
    const newSpan = {
      id: selectedRefId,
      startInd: Math.min(sel?.anchorOffset, sel?.focusOffset),
      endInd: Math.max(sel?.anchorOffset, sel?.focusOffset) - 1,
      label: "TBD",
      inSubject,
    };

    let newSpans = spans?.filter(s => s?.label !== "TBD") || [];
    if (newSpan?.startInd !== newSpan?.endInd + 1) {
      newSpans.push(newSpan);
    }

    setSpans(newSpans);
  };

  const onSelectLabelChange = e => {
    let newSpans = spans.map(span => {
      if (span?.label === "TBD") {
        return { ...span, label: e.target.value };
      }
      return span;
    });
    setSpans(newSpans);
    setSelectedLabel("");
  };

  useEffect(() => {
    // setSelectedRefId("1");
    setSelectedRefId(uniqueReferences?.[0]?.id);
  }, [JSON.stringify(uniqueReferences)]);

  useEffect(() => {
    setZoomFactor(1);
    if (!isOpen) {
      return;
    }

    doPopulatePageResults();
  }, [isOpen, selectedMeta?.fileId, selectedMeta?.pageNumber]);

  const doPopulatePageResults = async () => {
    const { data } = await getFilePageResults(
      selectedMeta?.fileId,
      selectedMeta?.pageNumber || 0
    );
    setPageResults(data);

    const { data: fileData } = await getFile(selectedMeta?.fileId);
    setFile(fileData);
  };

  const isMobile = window?.screen?.width < 1240;

  if (isMobile) {
    return (
      <>
        <Span
          className={className}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </Span>
        <Modal open={isOpen} handleClose={() => setOpen(false)}>
          <ModalContent>
            Reference checking only available on desktop
          </ModalContent>
        </Modal>
      </>
    );
  }

  let isReferenceAvailable = selectedMeta?.fileId || selectedMeta?.rawText;

  let subText = `(page ${(selectedMeta?.pageNumber || 0) + 1})`;
  if (selectedMeta?.rawText) {
    subText = "(email)";
  }

  const columnsByGroupName = groupBy(columns, "groupName");
  const groupNames = Object.keys(columnsByGroupName || {});

  return (
    <>
      <Span
        className={className}
        isDisabled={isDisabled}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        {children}
      </Span>
      <Modal open={isOpen} handleClose={() => setOpen(false)}>
        <ModalContent>
          <TitleContainer style={{ paddingLeft: 20 }}>Sources</TitleContainer>

          <NoFileText
            style={{
              paddingTop: 20,
              display: isReferenceAvailable ? "none" : "grid",
              alignSelf: "start",
            }}
          >
            <WarningIcon />
            No files linked
          </NoFileText>
          <div style={{ display: isReferenceAvailable ? "block" : "none" }}>
            <div style={{ backgroundColor: "white" }}>
              <FileLink
                style={{
                  display: selectedMeta?.fileId ? "block" : "none",
                  position: "absolute",
                  top: 120,
                  right: 0,
                }}
                href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                target="_blank"
              >
                <PdfIcon />
              </FileLink>
              <Tabs>
                {uniqueReferences?.map(ref => (
                  <Tab
                    isSelected={selectedRefId === ref?.id}
                    onClick={() => setSelectedRefId(ref?.id)}
                    key={ref?.id}
                  >
                    <ShortDiv>{ref?.fileName || ref?.subject}</ShortDiv>
                    {subText}
                  </Tab>
                ))}
              </Tabs>
            </div>

            {selectedMeta?.rawText && (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                  }}
                >
                  <EmailSubject
                    onMouseDown={() => setIsMouseDown(true)}
                    onMouseUp={() => onMouseUp({ inSubject: true })}
                  >
                    {selectedMeta?.subject}
                    <SpansContainer
                      style={{
                        pointerEvents: isMouseDown ? "none" : "auto",
                        padding: "8px 20px",
                      }}
                    >
                      {renderSegments(subjectSegments)}
                    </SpansContainer>
                  </EmailSubject>
                  <Select
                    value={selectedLabel}
                    disabled={!window?.getSelection()?.toString()}
                    onChange={onSelectLabelChange}
                  >
                    <option value="">-- Choose label --</option>
                    {groupNames?.map(groupName => (
                      <optgroup key={groupName} label={groupName}>
                        {columnsByGroupName[groupName]?.map(column => (
                          <option key={column?.name} value={column?.name}>
                            {last(column?.name?.split("_"))}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Select>
                </div>
                <EmailContent
                  onMouseDown={() => setIsMouseDown(true)}
                  onMouseUp={() => onMouseUp({ inSubject: false })}
                >
                  {selectedMeta?.rawText}
                  <SpansContainer
                    style={{ pointerEvents: isMouseDown ? "none" : "auto" }}
                  >
                    {renderSegments(textSegments)}
                  </SpansContainer>
                </EmailContent>
              </>
            )}

            {selectedMeta?.fileId && (
              <DocContainer>
                <ZoomButtons>
                  <ZoomButton
                    onClick={() =>
                      setZoomFactor(prev => Math.max(prev - 0.5, 1))
                    }
                  >
                    -
                  </ZoomButton>
                  <ZoomButton
                    onClick={() => setZoomFactor(p => Math.min(p + 0.5, 10))}
                  >
                    +
                  </ZoomButton>
                  <UploadedText>
                    Uploaded: {safeFormat(file?.createdAt, "d MMM HH:mm ")}
                  </UploadedText>

                  <DocAnchor
                    target="_blank"
                    rel="noreferrer"
                    href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                  >
                    View file
                    <PdfIcon />
                  </DocAnchor>
                </ZoomButtons>
                <ImgWithRectangleFlexibleStyled
                  zoomFactor={zoomFactor}
                  isLoading={!pageResults?.image}
                  // rectangles={selectedMeta?.references}
                  src={`data:image/png;base64,${pageResults?.image || ""}`}
                  alt="page preview"
                />
              </DocContainer>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RecordFilesModal;
