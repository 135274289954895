import useClickOutside from "hooks/useClickOutside";
import { clamp } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import {
  ARROW_KEYS,
  BULLET_1,
  EMPTY_DOC,
  SF,
  VIEW_W,
  addMultiClickSelection,
  deleteText,
  drawDoc,
  getNearestCharIndexFromEvent,
  getStartEnd,
  insertText,
  isListParagraph,
  moveCaret,
} from "utils/word-utils-refactor";

const Container = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);

  display: grid;
  justify-content: center;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: ${VIEW_W}px;
  margin-top: 0px;
  border: none;
`;

const initCanvasSize = canvasRef => {
  const canvas = canvasRef?.current;
  if (!canvas) {
    return;
  }
  const rect = canvas.getBoundingClientRect();
  canvas.style.height = `${rect.height}px`;

  canvas.setAttribute("height", rect.height * SF);
  canvas.setAttribute("width", rect.width * SF);
};

const printGoogleDocContent = async e => {
  e?.clipboardData?.types?.forEach(type => {
    console.log(type);
    if (!type?.includes("application/x-vnd.google-docs-document")) {
      return;
    }
    const data = e.clipboardData.getData(type);
    console.log(JSON.parse(JSON.parse(data)?.data));
  });
};

const DocArea = ({
  className,
  doc = EMPTY_DOC,
  onDocChange = newDoc => {},
  topMargin = 160,
}) => {
  const [searchParams] = useSearchParams();

  doc = doc || EMPTY_DOC;

  const [scrollY, setScrollY] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [xs, setXs] = useState([]);
  const [ys, setYs] = useState([]);
  const [isFocussed, setIsFocussed] = useState(false);

  const canvasRef = useRef();

  useClickOutside(canvasRef, () => setIsFocussed(false));

  const { text, styles, selStart, selEnd } = doc;

  // init: size
  useEffect(() => {
    initCanvasSize(canvasRef);
  }, [canvasRef]);

  // init event listeners
  useEffect(() => {
    document.addEventListener("paste", onPaste);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("copy", onCopy);
    document.addEventListener("cut", onCut);

    return () => {
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("cut", onCut);
    };
  }, [selStart, selEnd, text?.length, styles?.length, isFocussed]);

  // redrawing loop
  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    const [newXs, newYs] = drawDoc({ ctx, doc, scrollY, xs, ys, topMargin });
    setXs(newXs || []);
    setYs(newYs || []);
  }, [
    text?.length,
    styles,
    selStart,
    selEnd,
    canvasRef,
    scrollY,
    searchParams?.get("factchecking"),
  ]);

  const onMouseDown = async e => {
    e.preventDefault();
    setIsMouseDown(true);
    setIsFocussed(true);
    const ind = getNearestCharIndexFromEvent(e, scrollY, xs, ys, text);
    const clampedInd = clamp(ind, 0, text?.length);
    let newDoc = { ...doc, selStart: clampedInd, selEnd: clampedInd };
    newDoc = addMultiClickSelection(newDoc, e);

    onDocChange(newDoc);

    if (e.detail === 1 && styles?.[clampedInd]?.url) {
      setIsMouseDown(false);
      window.open(styles[clampedInd].url, "_blank");
    }
  };

  const onCopy = e => {
    e.preventDefault();
    const [start, end] = getStartEnd(doc);
    const selectedText = text?.slice(start, end);
    e.clipboardData.setData("text", selectedText);
  };

  const onCut = e => {
    e.preventDefault();
    const [start, end] = getStartEnd(doc);
    const selectedText = text?.slice(start, end);
    e.clipboardData.setData("text", selectedText);

    const newDoc = deleteText({ doc });
    onDocChange(newDoc);
  };

  const onMouseUp = e => {
    setIsMouseDown(false);
  };

  const onMouseMove = e => {
    if (!isMouseDown) {
      return;
    }
    const ind = getNearestCharIndexFromEvent(e, scrollY, xs, ys, text);
    onDocChange({ ...doc, selEnd: ind });
  };

  const onPaste = e => {
    if (!isFocussed) {
      return;
    }

    e.preventDefault();
    // printGoogleDocContent(e);

    const textToInsert = e.clipboardData.getData("text");
    const newDoc = insertText({ doc, textToInsert });
    onDocChange(newDoc);
  };

  const onWheel = e => {
    setScrollY(prev => Math.max(0, prev + e?.nativeEvent?.deltaY * SF));
  };

  const onKeyDown = e => {
    if (!isFocussed) {
      return;
    }
    if (e.metaKey || e.ctrlKey) {
      // select all
      if (e?.metaKey && e.key === "a") {
        e.preventDefault();
        onDocChange({ ...doc, selStart: 0, selEnd: text?.length });
      }

      return;
    }

    if (ARROW_KEYS.includes(e.key)) {
      const newDoc = moveCaret({ doc, key: e.key, xs, ys });
      onDocChange(newDoc);
      return;
    }

    // if character key
    if (e.key.length === 1 || e.key === "Enter") {
      let charToInsert = e.key;

      if (e.key === "Enter") {
        charToInsert = "\n";
        if (isListParagraph({ text: doc?.text, i: selStart })) {
          charToInsert = BULLET_1;
        }
      }

      const newDoc = insertText({ doc, textToInsert: charToInsert });
      onDocChange(newDoc);
      return;
    }

    if (e.key === "Backspace") {
      const newDoc = deleteText({ doc });
      onDocChange(newDoc);
      return;
    }
  };

  return (
    <Container className={className}>
      <Canvas
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        ref={canvasRef}
      />
      {/* <div
        style={{
          position: "absolute",
          top: 100,
          right: 20,
        }}
      >
        numChars: {text?.length}, numPages: {Math.ceil(text?.length / 1500)}
      </div> */}
    </Container>
  );
};

export default DocArea;
