import { postEmailChatSummary } from "api/backend/emailServiceEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import { EmailIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 600px;
  height: 600px;
  border-radius: 24px;
  padding: 20px;

  ${props => props?.isDisabled && "opacity: 0.5; pointer-events: none;"}

  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  align-items: end;
`;

const VerticalFields = styled.div`
  display: grid;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  justify-content: end;
  justify-items: end;
`;

const StyledInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 4px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledTextArea = styled.textarea`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  resize: none;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  height: 300px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledEmailIcon = styled(EmailIcon)`
  width: 16px;
  height: 16px;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const SendEmailModal = ({ wordDoc = {} }) => {
  const [payload, setPayload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPayload({});
    setError(null);
    setIsLoading(false);
  }, [isOpen]);

  const doSendEmail = async () => {
    const reqPayload = {
      wordDocContent: wordDoc.content,
      emailRecord: {
        to: [payload?.recipient],
        subject: payload?.subject,
        bodyText: payload?.message,
      },
    };
    setIsLoading(true);
    const { error } = await postEmailChatSummary({}, reqPayload);
    setIsLoading(false);
    setError(error);
  };

  return (
    <>
      <StyledEmailIcon
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <ModalTitle>Send Email</ModalTitle>

          <VerticalFields>
            <StyledInput
              placeholder="Recipient"
              value={payload?.recipient || ""}
              onChange={e =>
                setPayload({ ...payload, recipient: e.target.value })
              }
            />
            <StyledInput
              placeholder="Subject"
              value={payload?.subject || ""}
              onChange={e =>
                setPayload({ ...payload, subject: e.target.value })
              }
            />
            <StyledTextArea
              placeholder="Message"
              value={payload?.message || ""}
              onChange={e =>
                setPayload({ ...payload, message: e.target.value })
              }
            />
          </VerticalFields>

          <TwoColumns>
            <ButtonWord disabled={isLoading} isPrimary onClick={doSendEmail}>
              Send
            </ButtonWord>
            <ButtonWord onClick={() => setIsOpen(false)}>Cancel</ButtonWord>
          </TwoColumns>

          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendEmailModal;
