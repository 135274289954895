import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LayoutApp from "components/LayoutApp";
import LayoutNew from "components/LayoutNew";
import { useState } from "react";
import styled from "styled-components";
import {
  PlusIcon,
  TickIcon,
  TrashIcon,
  WarningIcon,
} from "components/ui/Icons";
import { getColorFromString, uuidv4 } from "utils/common";
import { clamp, clone, cloneDeep, uniq } from "lodash";
import ButtonWord from "components/ui/ButtonWord";
import ColumnFieldInput from "components/ColumnFieldInput";
import { Gap } from "components/Layout";
import {
  getPipelineConfig,
  postPipelineConfigs,
  postPipelineConfigsCsvPreview,
  postPipelineConfigsUploadCsvV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import { doCreateInitialPipelineResources } from "utils/pipeline-utils";
import NavWithTabsWithArrows from "components/ui/NavWithTabsWithArrows";
import InnerColumnsEditor from "components/InnerColumnsEditor";
import { useEffect } from "react";
import { postWordDocsUploadSolutions } from "api/backend/wordDocsEndpoints";
import {
  getAppTemplate,
  getAppTemplates,
} from "api/backend/appTemplatesEndpoints";
import SaveTemplateModal from "components/SaveTemplateModal";
import { ArrowUpIcon } from "components/IconsNew";
import InnerCategoreisEditor from "components/InnerCategoriesEditor";
import InnerCategoriesEditor from "components/InnerCategoriesEditor";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";

const Container = styled.div`
  position: relative;
  padding: 32px;
  padding-top: 60px;
  display: grid;
  min-height: 100%;
  align-content: start;
  background: linear-gradient(180deg, #f3f5f7 0%, #f3f5f7 100%);
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  white-space: pre-wrap;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const TableGroupName = styled.div`
  padding: 8px 0;
  font-weight: 600;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  resize: none;
  height: 100px;
  :disabled {
    opacity: 0.5;
  }
`;
const StyledSelect = styled.select`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const ThTwoLine = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
  background-color: white;
`;

const TwoItems = styled.div`
  padding-top: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 300px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ErrorDiv = styled.div`
  color: salmon;
`;

const Label = styled.label`
  padding: 0 12px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  :hover {
    color: #eaeaea;
  }

  ${props =>
    props?.disabled &&
    `pointer-events: none;
    background-color: #e0e0e0;
  `}
`;

const UpDownArrows = styled.div`
  svg {
    cursor: pointer;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    :hover {
      background-color: #bdbdbd;
    }
  }
`;

const DragTd = styled.td`
  transition: background-color 0.2s;
  background-color: transparent;
  height: 10px;
`;

const ThreeDots = styled.div`
  cursor: move;
  font-weight: 900;
  font-size: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const INITIAL_PIPELINE_CONFIG = {
  name: "New App",
  description: "",
  aggregationComponents: [],
  layout: null,
  meta: {},
  sourceTables: [
    {
      id: "source0",
      columns: [
        // {
        //   id: uuidv4(),
        //   actionType: "extraction",
        //   description: "",
        //   displayGroup: "",
        //   displayName: "Name",
        //   type: "TEXT",
        //   formatType: "Text",
        // },
        // {
        //   id: uuidv4(),
        //   actionType: "extraction",
        //   description: "",
        //   displayGroup: "File Templates",
        //   displayName: "",
        //   type: "TEXT",
        // },
      ],
    },
  ],
};

const CRM_TABLE_COLUMNS = [
  {
    displayName: "From email",
    displayGroup: "default",
    description: "The email from address",
    type: "TEXT",
    actionType: "extraction",
  },
  {
    displayName: "Date",
    displayGroup: "default",
    description: "The email date",
    type: "TEXT",
    actionType: "extraction",
  },
  {
    displayName: "Subject",
    displayGroup: "default",
    description: "The email subject",
    type: "TEXT",
    actionType: "extraction",
  },
  {
    displayName: "Body",
    displayGroup: "default",
    description: "The email body",
    type: "TEXT",
    actionType: "extraction",
  },
];

const COLUMN_FIELDS = [
  { label: "Name", name: "displayName" },
  {
    label: "Action type",
    name: "actionType",
    type: "select",
    options: [
      { label: "Extraction", value: "extraction" },
      { label: "Manual", value: "manual" },
      { label: "Reasoning", value: "reasoning" },
      { label: "Data fetching", value: "data_fetching" },
    ],
  },
  { label: "Description", name: "description" },
  {
    label: "Type",
    name: "formatType",
    type: "select",
    options: [
      { label: "Text", value: "Text", dbType: "TEXT" },
      { label: "Number", value: "Number", dbType: "NUMBER" },
      { label: "Date", value: "Date", dbType: "DATETIME" },
      { label: "Table", value: "Table", dbType: "TABLE" },
      { label: "CRM Table", value: "CRM_TABLE", dbType: "TABLE" },
      { label: "Categorical", value: "Categorical", dbType: "TEXT" },
      { label: "Tags", value: "Tags", dbType: "TEXT" },
      { label: "Rank", value: "Rank", dbType: "NUMBER" },
      { label: "Long Text", value: "Long Text", dbType: "TEXT" },
      { label: "Currency", value: "Currency", dbType: "NUMBER" },
      { label: "Email", value: "Email", dbType: "TEXT" },
      { label: "Phone", value: "Phone", dbType: "TEXT" },
      { label: "Web Link", value: "Web Link", dbType: "TEXT" },
      { label: "Address", value: "Address", dbType: "TEXT" },
      { label: "Percentage", value: "Percentage", dbType: "NUMBER" },
    ],
  },
];

export const getFormatTypeFromDbType = dbType => {
  const formatTypeField = COLUMN_FIELDS.find(
    field => field?.name === "formatType"
  );
  return (
    formatTypeField?.options?.find(option => option?.dbType === dbType)
      ?.value || "Text"
  );
};

export const getDbTypeFromFormatType = formatType => {
  const formatTypeField = COLUMN_FIELDS.find(
    field => field?.name === "formatType"
  );
  return formatTypeField?.options?.find(option => option?.value === formatType)
    ?.dbType;
};

const FILE_COLUMN_FIELDS = [
  { label: "Name", name: "displayName" },
  { label: "File template", name: "fileTemplate" },
];

const InfoMsg = styled.div`
  display: flex;
  align-items: center;
`;

const WarningSpan = styled.span`
  color: ${props => props.theme.color.in_progress};
  svg {
    fill: ${props => props.theme.color.in_progress};
  }
  display: flex;
  align-items: center;
`;

const getColumnInstructions = column => {
  if (column?.formatType === "Tags") {
    return `Set up tags for "${column?.displayName}"`;
  }

  if (column?.formatType === "Rank") {
    return (
      <InfoMsg>
        Set up rank labels for "{column?.displayName}".&nbsp;&nbsp;
        <WarningSpan>
          <WarningIcon /> Must be ordered low to high.
        </WarningSpan>
      </InfoMsg>
    );
  }

  return `Set up categories for "${column?.displayName}"`;
};

const updateSourceTableColumns = (
  pipelineConfig,
  selectedTableId,
  newColumns
) => {
  const selectedTable = pipelineConfig.sourceTables.find(
    table => table.id === selectedTableId
  );

  const newTable = cloneDeep(selectedTable);
  newTable.columns = newColumns;

  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.sourceTables = newPipelineConfig.sourceTables.map(table =>
    table.id === selectedTableId ? newTable : table
  );

  return newPipelineConfig;
};

const addIdsToColumns = pipelineConfig => {
  if (!pipelineConfig) {
    return pipelineConfig;
  }

  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.sourceTables = newPipelineConfig.sourceTables.map(table => {
    table.columns =
      table?.columns?.map(column => {
        if (column?.type === "TABLE") {
          return {
            ...column,
            id: uuidv4(),
            tableColumns: column?.tableColumns?.map(innerColumn => ({
              ...innerColumn,
              id: uuidv4(),
            })),
          };
        }

        return {
          ...column,
          id: uuidv4(),
        };
      }) || [];
    return table;
  });

  return newPipelineConfig;
};

const moveColumnToIndex = (
  pipelineConfig,
  selectedTableId,
  columnId,
  targetIndex
) => {
  const selectedTable = pipelineConfig.sourceTables.find(
    table => table.id === selectedTableId
  );
  const dropColumnIndex = selectedTable?.columns.findIndex(
    column => column.id === columnId
  );
  const droppedColumn = selectedTable?.columns?.[dropColumnIndex];
  let newColumns = cloneDeep(selectedTable?.columns)?.filter(
    col => col.id !== columnId
  );

  let sliceIndex = targetIndex;
  if (dropColumnIndex < targetIndex) {
    sliceIndex = targetIndex - 1;
  }

  newColumns = [
    ...newColumns?.slice(0, sliceIndex),
    droppedColumn,
    ...newColumns?.slice(sliceIndex),
  ];

  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.sourceTables = newPipelineConfig.sourceTables.map(table =>
    table.id === selectedTableId ? { ...table, columns: newColumns } : table
  );

  return newPipelineConfig;
};

const DRAG_INACTIVE_COLOR = "transparent";
const DRAG_ACTIVE_COLOR = "#bfbfbf";

const FileTemplatesGroup = ({
  columns,
  onChangeColumn,
  onClickTrash = columnId => {},
  onClickAddColumn = () => {},
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const onChangeWordDocTemplate = async (e, columnId) => {
    setIsUploading(true);
    const formDataBody = new FormData();
    for (let i = 0; i < e?.target?.files?.length; i++) {
      formDataBody.append("wordDocFile", e?.target?.files?.[i]);
    }
    const { data } = await postWordDocsUploadSolutions({}, formDataBody);

    const newColumns = cloneDeep(columns);
    const columnToEdit = newColumns.find(col => col.id === columnId);
    columnToEdit["description"] = data?.id;

    onChangeColumn(columnId, data?.id, "description");

    setIsUploading(false);
  };

  return (
    <div>
      <TableGroupName>File templates</TableGroupName>
      <Table style={{ width: "100%" }}>
        <thead>
          <tr>
            <Th>Name</Th>
            <Th>File template</Th>
            <Th />
          </tr>
        </thead>
        <tbody>
          {columns
            ?.filter(col => col?.displayGroup === "File Templates")
            ?.map(column => {
              return (
                <tr>
                  <Td>
                    <ColumnFieldInput
                      value={column?.displayName}
                      columnType="TEXT"
                      onChange={e =>
                        onChangeColumn(
                          column?.id,
                          e.target.value,
                          "displayName"
                        )
                      }
                    />
                  </Td>
                  <Td>
                    <input
                      disabled={isUploading}
                      type="file"
                      onChange={e => onChangeWordDocTemplate(e, column?.id)}
                    />
                    {column?.description && <TickIcon />}
                  </Td>
                  <Td>
                    <StyledTrashIcon onClick={() => onClickTrash(column?.id)} />
                  </Td>
                </tr>
              );
            })}
          <tr>
            <Td style={{ border: "none" }}>
              <StyledPlusIcon onClick={() => onClickAddColumn()} />
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const PipelineSettingsPage = () => {
  const { pipelineConfigId } = useParams();
  const navigate = useNavigate();

  const [pipelineConfig, setPipelineConfig] = useState(INITIAL_PIPELINE_CONFIG);
  const [savedPipelineConfig, setSavedPipelineConfig] = useState(null);

  const [selectedTableId, setSelectedTableId] = useState("source0");
  const [newGroupName, setNewGroupName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);
  const [csvPath, setCsvPath] = useState("");
  const [csvError, setCsvError] = useState(null);
  const [searchParams] = useSearchParams();

  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table.id === selectedTableId
  );

  const groupNames = uniq(
    selectedTable?.columns?.map(column => column?.displayGroup)
  )?.sort(name1 => (name1 === "" ? -1 : 1));

  const allTables = pipelineConfig?.sourceTables || [];

  useEffect(() => {
    if (pipelineConfigId !== "new") {
      doPopulatePipelineConfig();
    }
  }, [pipelineConfigId]);

  useEffect(() => {
    const templateId = searchParams?.get("templateId");
    if (!templateId) {
      return;
    }
    doPopulateTemplate(templateId);
  }, [searchParams?.get("templateId")]);

  const doPopulateTemplate = async templateId => {
    const { data } = await getAppTemplates();
    const template = data?.find(template => template?.id === templateId);

    let templateJson = null;
    try {
      templateJson = JSON.parse(template?.template);
    } catch (e) {
      //
    }
    if (templateJson?.config) {
      setPipelineConfig(addIdsToColumns(templateJson?.config));
    }
  };

  const doPopulatePipelineConfig = async () => {
    setIsLoading(true);
    const { data: pipelineConfig } = await getPipelineConfig(pipelineConfigId);
    const pipelineConfigWithIds = addIdsToColumns(pipelineConfig);
    setPipelineConfig(pipelineConfigWithIds);
    setSavedPipelineConfig(pipelineConfigWithIds);
    setSelectedTableId(pipelineConfig?.sourceTables?.[0]?.id);
    setIsLoading(false);
  };

  const onChangeConfig = (e, key) => {
    setPipelineConfig({ ...pipelineConfig, [key]: e.target.value });
  };

  const onChangeColumn = (columnId, newValue, key) => {
    const newColumns = cloneDeep(selectedTable?.columns || []);
    const indexOfId = newColumns.findIndex(column => column.id === columnId);
    if (indexOfId === -1) {
      return;
    }

    newColumns[indexOfId][key] = newValue;
    const column = newColumns?.[indexOfId];

    if (key === "formatType") {
      column.type = getDbTypeFromFormatType(newValue);
    }
    if (key === "formatType" && newValue === "CRM_TABLE") {
      const linkId = uuidv4();
      column.tableColumns = CRM_TABLE_COLUMNS;
      column.linkId = linkId;
      // insert column before this one
      newColumns.splice(indexOfId, 0, {
        linkId,
        actionType: "extraction",
        description: "Filter for table below",
        displayGroup: column?.displayGroup,
        displayName: `Filter for ${column?.displayName}`,
        type: "TEXT",
        formatType: "Text",
      });
    }

    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
  };

  const doSavePipelineConfig = async () => {
    setIsLoading(true);
    const { data: updatedConfig, error } = await putPipelineConfig(
      pipelineConfigId,
      {},
      pipelineConfig
    );

    if (error) {
      setIsLoading(false);
      setError(error);
      return;
    }

    const pipelineConfigWithIds = addIdsToColumns(updatedConfig);
    setPipelineConfig(pipelineConfigWithIds);
    setSavedPipelineConfig(pipelineConfigWithIds);
    setIsLoading(false);
  };

  const doCreateNewPipelineConfig = async () => {
    setIsLoading(true);
    const { data: createdConfig, error } = await postPipelineConfigs(
      {},
      pipelineConfig
    );

    if (error) {
      setIsLoading(false);
      setError(error);
      return;
    }

    await doCreateInitialPipelineResources(createdConfig);
    navigate(`/apps/${createdConfig?.id}/records`);
    setIsLoading(false);
  };

  const onClickDone = async () => {
    setError(null);

    if (csvPath) {
      setIsLoading(true);
      const { error } = await postPipelineConfigsUploadCsvV2(
        { csvPaths: [csvPath], pipelineName: pipelineConfig?.name },
        pipelineConfig
      );
      setIsLoading(false);
      setError(error);
      if (error) {
        return;
      }
      navigate(
        `/?sidebar=%7B"type"%3A"pipelines"%2C"filterTypes"%3A%5Bnull%5D%7D`
      );
      return;
    }

    if (pipelineConfigId === "new") {
      await doCreateNewPipelineConfig();
      return;
    }
    await doSavePipelineConfig();
  };

  const onChangeCsvFile = async e => {
    setIsUploadingCsv(true);

    const files = e?.target?.files || [];
    const formDataBody = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formDataBody.append("files", files[i]);
    }

    const { data, error } = await postPipelineConfigsCsvPreview(
      {},
      formDataBody
    );

    setCsvError(error);
    setCsvPath(data?.csvPath || "");
    setPipelineConfig(
      addIdsToColumns(data?.pipelineConfig) || INITIAL_PIPELINE_CONFIG
    );
    setIsUploadingCsv(false);
  };

  const addColumn = displayGroup => {
    const newColumn = {
      id: uuidv4(),
      actionType: "extraction",
      description: "",
      displayGroup,
      displayName: "Name",
      type: "TEXT",
    };

    const newColumns = [...selectedTable.columns, newColumn];
    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
  };

  const removeColumn = columnId => {
    const newColumns = selectedTable.columns.filter(
      column => column.id !== columnId
    );

    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
  };

  const addNewGroup = () => {
    const newColumn = {
      id: uuidv4(),
      actionType: "extraction",
      description: "",
      displayGroup: newGroupName,
      displayName: "",
      type: "TEXT",
      formatType: "Text",
    };

    const newColumns = [...selectedTable.columns, newColumn];
    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
    setNewGroupName("");
  };

  const addNewTable = () => {
    const newTable = {
      id: `source${allTables?.length}`,
      columns: [
        {
          id: uuidv4(),
          actionType: "extraction",
          description: "",
          displayGroup: "",
          displayName: "Name",
          type: "TEXT",
        },
      ],
    };

    const newPipelineConfig = cloneDeep(pipelineConfig);
    newPipelineConfig.sourceTables.push(newTable);
    setPipelineConfig(newPipelineConfig);
  };

  const removeGroup = groupName => {
    const newColumns = selectedTable.columns.filter(
      column => column.displayGroup !== groupName
    );

    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
  };

  const moveColumnUpOrDown = (columnId, direction = 1) => {
    const selectedTable = pipelineConfig.sourceTables.find(
      table => table.id === selectedTableId
    );
    const newTable = cloneDeep(selectedTable);

    const columnIndex = newTable.columns.findIndex(
      column => column.id === columnId
    );
    const sourceColumn = cloneDeep(newTable.columns[columnIndex]);

    const targetIndex = clamp(
      columnIndex + direction,
      0,
      newTable.columns.length - 1
    );
    const targetColumn = cloneDeep(newTable.columns[targetIndex]);

    newTable.columns = newTable.columns.map((column, index) => {
      if (index === targetIndex) {
        return sourceColumn;
      }
      if (index === columnIndex) {
        return targetColumn;
      }
      return column;
    });

    const newPipelineConfig = cloneDeep(pipelineConfig);
    newPipelineConfig.sourceTables = newPipelineConfig.sourceTables.map(table =>
      table.id === selectedTableId ? newTable : table
    );

    setPipelineConfig(newPipelineConfig);
  };

  const onDragOver = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    e.target.style.backgroundColor = DRAG_ACTIVE_COLOR;
  };

  const onDrop = (e, columnIndex) => {
    e.target.style.backgroundColor = DRAG_INACTIVE_COLOR;
    onDropColumn(e, columnIndex);
  };

  const onDragLeave = e => {
    e.target.style.backgroundColor = DRAG_INACTIVE_COLOR;
  };

  const onDragStart = (e, groupColumn) => {
    e.dataTransfer.setData("application/columnId", groupColumn?.id);
    e.dataTransfer.effectAllowed = "move";
  };

  const onDropColumn = (e, columnIndex) => {
    e.preventDefault();
    const droppedColumnId = e.dataTransfer.getData("application/columnId");
    const newPipelineConfig = moveColumnToIndex(
      pipelineConfig,
      selectedTableId,
      droppedColumnId,
      columnIndex
    );
    setPipelineConfig(newPipelineConfig);
  };

  const LayoutComponent = pipelineConfigId === "new" ? LayoutNew : LayoutApp;

  let summaryColumns = pipelineConfig?.meta?.summaryColumns || [];

  const onChangeSummaryTickbox = (e, groupColumn) => {
    const newPipelineConfig = cloneDeep(pipelineConfig);
    if (!newPipelineConfig.meta.summaryColumns) {
      newPipelineConfig.meta.summaryColumns = [];
    }
    if (e.target.checked) {
      newPipelineConfig.meta.summaryColumns = [
        ...newPipelineConfig.meta.summaryColumns,
        groupColumn?.dbName,
      ];
    } else {
      newPipelineConfig.meta.summaryColumns =
        newPipelineConfig.meta.summaryColumns.filter(
          dbName => dbName !== groupColumn?.dbName
        );
    }
    setPipelineConfig(newPipelineConfig);
  };

  const onChangeNameColumn = selectedId => {
    const newColumns = selectedTable?.columns?.map(column => {
      if (column?.id === selectedId) {
        return { ...column, isNameField: true };
      }
      return { ...column, isNameField: false };
    });

    const newPipelineConfig = updateSourceTableColumns(
      pipelineConfig,
      selectedTableId,
      newColumns
    );
    setPipelineConfig(newPipelineConfig);
  };

  let doneText = "Create app";
  if (pipelineConfigId !== "new") {
    doneText = "Save";
  }
  if (csvPath) {
    doneText = "Create app + CSV records";
  }

  let doneBtn = (
    <ButtonWord
      style={{ padding: "4px 20px", justifySelf: "end" }}
      isPrimary
      onClick={onClickDone}
    >
      {doneText}
    </ButtonWord>
  );
  if (csvPath) {
    doneBtn = (
      <ConfirmationModalTrigger
        message="Ensure all data types are correct. Types affect analytics."
        yesText={doneText}
        onClickYes={onClickDone}
        icon={
          <ButtonWord
            style={{ padding: "4px 20px", justifySelf: "end" }}
            isPrimary
          >
            {doneText}
          </ButtonWord>
        }
      />
    );
  }

  const nameColumnId =
    selectedTable?.columns?.find(col => col?.isNameField)?.id || "";

  return (
    <LayoutComponent>
      <Container isDisabled={isLoading}>
        <Title>Configure fields</Title>
        <TableGroupName>App name *</TableGroupName>
        <StyledInput
          style={{ width: 300, backgroundColor: "white" }}
          value={pipelineConfig?.name}
          onChange={e => onChangeConfig(e, "name")}
          placeholder="App name"
        />
        <TableGroupName>App description (optional)</TableGroupName>
        <StyledTextArea
          style={{ width: 300, backgroundColor: "white" }}
          value={pipelineConfig?.description}
          onChange={e => onChangeConfig(e, "description")}
          placeholder="App description"
        />
        <TabsContainer>
          <NavWithTabsWithArrows
            tabNames={allTables.map(table => table?.id)}
            onTabSelect={tableId => setSelectedTableId(tableId)}
            selectedTabName={selectedTableId}
          />
          <StyledPlusIcon onClick={addNewTable} />
        </TabsContainer>
        <Label
          style={{ marginTop: 20, justifySelf: "start" }}
          disabled={isUploadingCsv}
        >
          Upload CSV
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onChangeCsvFile}
          />
        </Label>
        {csvError && <ErrorDiv>{csvError?.message}</ErrorDiv>}
        <FileTemplatesGroup
          columns={selectedTable?.columns}
          onChangeColumn={onChangeColumn}
          onClickTrash={columnId => removeColumn(columnId)}
          onClickAddColumn={() => addColumn("File Templates")}
        />
        <TableGroupName>Select name column</TableGroupName>
        <StyledSelect
          value={nameColumnId}
          onChange={e => onChangeNameColumn(e.target.value)}
          style={{ margin: "10px 0", justifySelf: "start" }}
        >
          <option>-- Choose name column --</option>
          {selectedTable?.columns?.map(column => (
            <option key={column?.dbName} value={column?.id}>
              {column?.displayName}
            </option>
          ))}
        </StyledSelect>
        {groupNames
          ?.filter(groupName => groupName !== "File Templates")
          ?.map(groupName => {
            const groupColumns = selectedTable?.columns?.filter(
              column => column?.displayGroup === groupName
            );

            return (
              <div key={groupName}>
                <TableGroupName>
                  {groupName || "Record ID"}
                  {groupName && (
                    <StyledTrashIcon onClick={() => removeGroup(groupName)} />
                  )}
                </TableGroupName>
                <Table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      {COLUMN_FIELDS.map(columnField => (
                        <Th key={columnField.name}>{columnField.label}</Th>
                      ))}
                      <ThTwoLine>
                        Summary
                        <br />
                        column?
                      </ThTwoLine>
                      <Th />
                    </tr>
                  </thead>
                  <tbody>
                    {groupColumns.map(groupColumn => {
                      const savedGroupColumn = savedPipelineConfig?.sourceTables
                        ?.find(table => table.id === selectedTableId)
                        ?.columns?.find(
                          column => column.id === groupColumn?.id
                        );
                      let fieldStyle = {};
                      if (groupColumn?.linkId) {
                        fieldStyle = {
                          borderRight: `4px solid ${getColorFromString(
                            groupColumn?.linkId
                          )}44`,
                        };
                      }

                      const columnIndex = selectedTable?.columns?.findIndex(
                        column => column.id === groupColumn?.id
                      );

                      return (
                        <>
                          <tr
                            onDrop={e => onDrop(e, columnIndex)}
                            onDragOver={onDragOver}
                            onDragLeave={onDragLeave}
                          >
                            <DragTd colSpan={7} />
                          </tr>
                          <tr
                            draggable
                            onDragStart={e => onDragStart(e, groupColumn)}
                          >
                            {COLUMN_FIELDS.map((columnField, i) => {
                              let value = groupColumn?.[columnField?.name];
                              if (
                                columnField?.name === "formatType" &&
                                !value
                              ) {
                                value = getFormatTypeFromDbType(
                                  groupColumn?.type
                                );
                              }

                              return (
                                <Td key={columnField.name}>
                                  <FlexContainer>
                                    {i === 0 && <ThreeDots>⋮</ThreeDots>}
                                    <ColumnFieldInput
                                      value={value}
                                      columnType={columnField?.type}
                                      options={columnField?.options}
                                      onChange={e =>
                                        onChangeColumn(
                                          groupColumn?.id,
                                          e.target.value,
                                          columnField?.name
                                        )
                                      }
                                    />
                                  </FlexContainer>
                                </Td>
                              );
                            })}
                            <Td key="isSummary">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={summaryColumns?.includes(
                                    groupColumn?.dbName
                                  )}
                                  onChange={e =>
                                    onChangeSummaryTickbox(e, groupColumn)
                                  }
                                />
                              </div>
                            </Td>
                            {groupName && (
                              <Td style={fieldStyle}>
                                <StyledTrashIcon
                                  onClick={() => removeColumn(groupColumn?.id)}
                                />
                              </Td>
                            )}
                          </tr>
                          {groupColumn?.type === "TABLE" && (
                            <tr>
                              <td colSpan={5} style={fieldStyle}>
                                <BoldDiv style={{ padding: "10px" }}>
                                  Set up columns for "{groupColumn?.displayName}
                                  "
                                </BoldDiv>
                                <InnerColumnsEditor
                                  isDisabled={
                                    groupColumn?.formatType === "CRM_TABLE"
                                  }
                                  tableColumn={groupColumn}
                                  columns={groupColumn?.tableColumns || []}
                                  onChangeColumns={innerColumns =>
                                    onChangeColumn(
                                      groupColumn?.id,
                                      innerColumns,
                                      "tableColumns"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {["Categorical", "Tags", "Rank"]?.includes(
                            groupColumn?.formatType
                          ) && (
                            <tr>
                              <td colSpan={5} style={fieldStyle}>
                                <BoldDiv style={{ padding: "10px" }}>
                                  {getColumnInstructions(groupColumn)}
                                </BoldDiv>
                                <InnerCategoriesEditor
                                  isFullDeletionAllowed={
                                    groupColumn?.formatType !== "Rank"
                                  }
                                  savedColumnStrings={
                                    savedGroupColumn?.categories || []
                                  }
                                  columnsStrings={groupColumn?.categories || []}
                                  onChangeColumns={newCategories => {
                                    onChangeColumn(
                                      groupColumn?.id,
                                      newCategories,
                                      "categories"
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                    {groupName && (
                      <tr>
                        <Td style={{ border: "none" }}>
                          <StyledPlusIcon
                            onClick={() => addColumn(groupName)}
                          />
                        </Td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            );
          })}
        <TwoItems>
          <StyledInput
            style={{ backgroundColor: "white" }}
            value={newGroupName}
            onChange={e => setNewGroupName(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                addNewGroup();
              }
            }}
            placeholder="New Group Name"
          />
          <ButtonWord disabled={!newGroupName} onClick={addNewGroup}>
            Add
          </ButtonWord>
        </TwoItems>
        <Gap />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SaveTemplateModal
            name={pipelineConfig?.name}
            description={pipelineConfig?.description}
            config={pipelineConfig}
          />
          {doneBtn}
        </div>
        <Gap />
        <ErrorDiv>{error?.message || ""}</ErrorDiv>
      </Container>
    </LayoutComponent>
  );
};

export default PipelineSettingsPage;
