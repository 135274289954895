import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  deleteDirectory,
  getDirectoryItems,
  postDirectories,
  postRenameDirectory,
} from "api/services/fileSystem";
import {
  ChevronDownIcon,
  DownloadIcon,
  EmailIcon,
  ExcelIcon,
  FilesIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  TrashIcon,
  WordIcon,
} from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import {
  deleteExcelModelById,
  patchExcelModelById,
  postExcelModelUploadFile,
} from "api/services/excelModelsService";
import {
  deleteWordDoc,
  patchWordDoc,
  postWordDoc,
  postWordDocsUpload,
} from "api/services/wordDocsService";
import {
  deleteSlideDoc,
  patchSlideDoc,
  postSlideDocsUpload,
} from "api/services/slideDocsService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CircularProgress } from "@material-ui/core";
import {
  deleteFileById,
  patchFileById,
  postAudioFilesFormData,
  postBulkUploadFormData,
} from "api/services/filesService";
import { last } from "lodash";
import {
  Audiotrack,
  FileCopy,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";
import Modal from "components/ui/Modal";
import InputWithStateNew from "components/InputWithStateNew";
import { getLoggedInUserName } from "api/services/authenticationService";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import {
  postSlideDoc,
  triggerDownloadOfSlideDoc,
} from "api/backend/slideDocsEndpoints";
import useSearchParamsState from "hooks/useSearchParamsState";
import {
  BinIcon,
  ChatIcon,
  FolderPlusIcon,
  RenameIcon,
  ShareIcon,
} from "components/IconsNew";
import { format } from "date-fns";
import IngestWebpageModal from "components/IngestWebpageModal";
import { uuidv4 } from "utils/common";
import { postExcelModelDraft } from "api/backend/excelModelsEndpoints";
import CreateWordModalTrigger from "components/CreateWordModalTrigger";
import {
  getWordDoc,
  postWordDocsUploadSolutions,
} from "api/backend/wordDocsEndpoints";
import { postMoveItem } from "api/backend/fileSystemEndpoints";
import { useNavigate, useSearchParams } from "react-router-dom";
import PopoverClick from "components/ui/PopoverClick";

const Container = styled.div`
  position: relative;
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledTooltipClick = styled(TooltipClick)`
  position: absolute;
  right: 0;
  top: 9px;
  right: 4px;
`;

const ActionsTrigger = styled.div`
  opacity: 0;
  z-index: 1;
  height: 16px;
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding: 0 4px;
  background-color: #d8d8d8;
  cursor: pointer;
  transition: opacity 0.1s;

  :hover {
    background-color: #a0a0a0;
  }
`;

const DirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: 22px 1fr auto;
  padding: 8px 0;
  padding-right: 20px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;

  :hover {
    background-color: #000000;
    ${ActionsTrigger} {
      opacity: 1;
    }
  }
`;

const FolderItem = styled.div``;

const ItemName = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eaeaea;
  font-weight: 500;
  text-decoration: none;
`;

const StyledInputWithStateNew = styled(InputWithStateNew)`
  color: #eaeaea;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

const DirItemName = styled.div`
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eaeaea;
  font-weight: 500;
`;

const StyledDirInputWithStateNew = styled(InputWithStateNew)`
  color: #eaeaea;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;

  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledChatIcon = styled(ChatIcon)`
  height: 14px;
  /* width: 10px; */
  fill: white;
  margin-left: 2px;
  margin-top: 3px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
  margin-left: 10px;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
  fill: white;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledAudioIcon = styled(Audiotrack)`
  width: 12px;
  margin-left: 10px;
  color: white;
`;

const StyledActionWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledActionChatIcon = styled(ChatIcon)`
  /* height: 14px; */
  margin-left: 0px;
  width: 10px;
`;

const StyledActionTrashIcon = styled(TrashIcon)`
  height: 14px;
`;

const StyledActionFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledActionPencilIcon = styled(PencilIcon)`
  height: 14px;
`;

const StyledActionPdfIcon = styled(PdfIcon)`
  height: 14px;
`;

const StyledActionWebIcon = styled(InternetIcon)`
  height: 14px;
`;

const StyledActionAudioIcon = styled(Audiotrack)`
  height: 14px;
`;

const StyledActionEmailIcon = styled(EmailIcon)`
  height: 14px;
`;

const NewItemFileInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  ::file-selector-button {
    display: none;
  }
`;

const Actions = styled.div`
  box-shadow: 2px 2px 7px 0px #00000040;
  border-radius: 8px;
  padding: 8px 0;
`;

const ActionItem = styled.div`
  height: 30px;
  padding: 0 15px;
  padding-right: 50px;
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 15px;

  svg {
    fill: #000000;
    width: 18px;
    height: 16px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const DirNameHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 5px 0;
  gap: 8px;
  align-items: center;
  ${props => props.isSticky && "position: sticky; top: 0;"}
  justify-content: space-between;
  z-index: 1;

  cursor: pointer;
  :hover {
    background-color: #000000;
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: #696969;
  border-radius: 50%;
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 3px;
`;

const StyledFilesIcon = styled(FilesIcon)`
  height: 14px;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ModalContent = styled.div`
  padding: 20px 40px;
`;

const SendEmailModal = ({ trigger = "open" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{trigger}</div>
      <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <div>
            Send email to <BoldSpan>data@boltzbit.io</BoldSpan>{" "}
            <StyledFilesIcon
              onClick={() => navigator.clipboard.writeText("data@boltzbit.io")}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const ThreeDots = () => (
  <DotsContainer>
    <Dot />
    <Dot />
    <Dot />
  </DotsContainer>
);

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
  CHAT_DOC: <StyledChatIcon />,
};

const getItemIcon = item => {
  if (item?.isTemplate) {
    return <StyledSigmaIcon />;
  }

  if (item?.isAudioFile) {
    return (
      <StyledAudioIcon
        style={{ width: "12px", height: "12px", marginTop: "2px" }}
      />
    );
  }

  return TYPE_TO_ICON[item?.type];
};

const getItemUrl = item => {
  if (item?.isTemplate) {
    return `/trial-dashboards/${item?.id}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.id}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.id}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.id}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.id}`;
  }

  if (item?.type === "CHAT_DOC") {
    return `/chat-docs/${item?.id}`;
  }

  return "";
};

const getNewFolderName = directoryItems => {
  const existingFolderNames = directoryItems
    .filter(item => item?.type === "DIRECTORY")
    .filter(item => item?.path.includes("New Folder"))
    .map(item => last(item?.path?.split("/")));

  let newFolderName = "New Folder";
  let i = 1;
  while (existingFolderNames.includes(newFolderName)) {
    newFolderName = `New Folder ${i}`;
    i++;
  }
  return newFolderName;
};

const StyledPlusIcon = styled(PlusIcon)`
  height: 12px;
  :hover {
    opacity: 0.5;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 8px;
  fill: none;
  stroke: white;
`;

const DirNameDropZone = styled.div`
  ${props => props?.isDraggingOver && "background-color: #0000ff22;"}
  ${props =>
    props?.isDraggingOver &&
    `
    * {
      background-color: #0000ff15;
    }
  `}
`;

export const ITEM_TYPE_TO_NAME_KEY = {
  WORD_DOC: "fileName",
  EXCEL_MODEL: "name",
  SLIDE_DOC: "fileName",
  FILE: "fileName",
  DIRECTORY: "path",
  CHAT_DOC: "fileName",
};

export const formatItemName = (item, itemName) => {
  if (item?.isAudioFile) {
    return itemName?.replaceAll(".pdf", "");
  }

  return itemName;
};

const downloadItem = item => {
  if (item?.type === "SLIDE_DOC") {
    triggerDownloadOfSlideDoc(item?.id);
    return;
  }
  triggerDownloadOfFile(item?.id, { fileType: item?.type });
};

const doCreateSlideDoc = async (directoryPath = "/working-files") => {
  const { data, error } = await postSlideDoc(
    { directoryPath },
    {
      fileName: `Untitled Presentation`,
      content: {
        slides: [
          {
            boxes: [
              {
                id: uuidv4(),
                x: 100,
                y: 100,
                w: 200,
                h: 150,
                text: "Add text here",
                styles: [],
                boxStyle: {
                  bgColor: "#ffffff",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#000000",
                },
                isSelected: true,
              },
            ],
          },
        ],
      },
    }
  );
  if (!error) {
    window.open(`/slide-docs/${data?.id}`, "_blank");
  }
};

const doUploadSlideDoc = async (directoryPath, file) => {
  const body = new FormData();
  body.append("pptFile", file);
  const { data, error } = await postSlideDocsUpload(
    {
      directoryPath,
    },
    body
  );
  if (!error) {
    window.open(`/slide-docs/${data?.id}`, "_blank");
  }
};

const doCreateExcelModel = async directoryPath => {
  const { data: newModel, error } = await postExcelModelDraft({
    directoryPath,
  });
  if (!error) {
    window.open(`/excel-views/${newModel?.id}`, "_blank");
  }
};

const doUploadExcelFile = async (directoryPath, file) => {
  const { data, error } = await postExcelModelUploadFile(
    { directoryPath },
    file
  );
  if (!error) {
    window.open(`/excel-views/${data?.id}`, "_blank");
  }
};

const HasMoreBtn = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px 0;
  padding-right: 0px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 40px;

  :hover {
    background-color: #000000;
    ${ActionsTrigger} {
      opacity: 1;
    }
  }
  svg {
    color: #0191ff;
  }
`;

const ItemsCount = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px 0;
  padding-right: 0px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 58px;

  :hover {
    background-color: #000000;
    ${ActionsTrigger} {
      opacity: 1;
    }
  }
  svg {
    color: #0191ff;
  }
`;

const INDENT_PX = 16;

const DirectoryItemsList = ({
  directoryPath = "/working-files",
  isInitiallyOpen = false,
  isHeaderSticky = false,
  parentNewItemIds = [],
  isDeleteActionHidden = false,
  onRemoveSelf = () => {},
  indentPx = 0,
  isIconDisabled = false,
  folderActions = [],
  rootItem = {},
  renderHeaderComponent = null,
  onApplyNewPath = newPath => {},
  isNewFolderActionHidden = false,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [directoryItems, setDirectoryItems] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [newItemIds, setNewItemIds] = useState([]);
  const [isShowingFolderActions, setIsShowingFolderActions] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [isEditingDirectoryName, setIsEditingDirectoryName] = useState(false);
  const [sidebar] = useSearchParamsState({
    paramName: "sidebar",
    initialValue: "",
  });
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isGreyedOut, setIsGreyedOut] = useState(false);
  const [itemsRes, setItemsRes] = useState(null);

  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    if (isOpen || parentNewItemIds?.length > 0) {
      doPopulateDirectoryItems();
    }
  }, [isOpen, parentNewItemIds?.length, pageSize]);

  useEffect(() => {
    if (isOpen && searchParams?.get("refreshFiles") === "true") {
      doPopulateDirectoryItems();
      searchParams.delete("refreshFiles");
      navigate(`?${searchParams.toString()}`);
    }
  }, [searchParams?.get("refreshFiles")]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (directoryItems?.some(dirItem => dirItem?.status === "Processing")) {
      const intervalId = setInterval(() => {
        doPopulateDirectoryItems();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [JSON.stringify(directoryItems), isOpen]);

  // useEffect(() => {
  //   const doFetchDirectoryItems = async () => {
  //     const { data } = await getDirectoryItems({
  //       directoryPath,
  //     });
  //     setDirectoryItems(
  //       data?.items?.filter(item => item?.id !== itemIdBeingDeleted) || []
  //     );
  //   };

  //   const intervalId = setInterval(() => {
  //     if (isOpen) {
  //       doFetchDirectoryItems();
  //     }
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, [directoryPath, itemIdBeingDeleted]);

  // console.log({ itemsRes });

  const doPopulateDirectoryItems = async () => {
    setIsLoadingItems(true);

    let params = { pageSize, directoryPath };
    const { data } = await getDirectoryItems(params);
    setItemsRes(data);
    setDirectoryItems(prev => [
      ...(data?.items?.filter(item => item?.id !== itemIdBeingDeleted) || []),
    ]);
    setIsLoadingItems(false);
    setIsGreyedOut(false);
  };

  const doCreateChatDoc = async dirPath => {
    const wordDocPayload = {
      directoryPath: dirPath,
      fileName: `Chat on ${format(new Date(), "dd MMM HH:mm")}`,
      isChat: true,
      content: {
        sources: [],
        blocks: [],
      },
    };

    const { data, error } = await postWordDoc({}, wordDocPayload);
    if (!error) {
      setNewItemIds(prev => [...prev, data?.id]);
      window.open(`/chat-docs/${data?.id}`, "_blank");
      doPopulateDirectoryItems();
    }
  };

  const doUploadWordDoc = async (dirPath, e) => {
    const formDataBody = new FormData();
    for (let i = 0; i < e?.target?.files?.length; i++) {
      formDataBody.append("wordDocFile", e?.target?.files?.[i]);
    }
    const { error } = await postWordDocsUploadSolutions(
      {
        directoryPath: dirPath,
      },
      formDataBody
    );
    setError(error);
    doPopulateDirectoryItems();
  };

  const doCreateNewFolder = async dirPath => {
    const { data, error } = await postDirectories({
      path: `${dirPath}/${getNewFolderName(directoryItems)}`,
    });
    if (!error) {
      setNewItemIds(prev => [...prev, data?.id]);
      doPopulateDirectoryItems();
    }
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?.id !== item?.id)
    );

    let error = null;

    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await deleteExcelModelById(item?.id));
    }

    if (item?.type === "DIRECTORY") {
      ({ error } = await deleteDirectory({ directoryPath: item?.path }));
    }

    if (item?.type === "WORD_DOC" || item?.type === "CHAT_DOC") {
      ({ error } = await deleteWordDoc(item?.id));
    }

    if (item?.type === "SLIDE_DOC") {
      ({ error } = await deleteSlideDoc(item?.id));
    }

    if (item?.type === "FILE") {
      ({ error } = await deleteFileById(item?.id));
    }

    setItemIdBeingDeleted(null);
    if (error) {
      setError(error);
      return;
    }
    if (item?.type === "DIRECTORY" && item?.path === directoryPath) {
      onRemoveSelf();
    }
    setDirectoryItems(
      directoryItems.filter(dirItem => dirItem?.id !== item?.id)
    );
  };

  const doPostFiles = async files => {
    const { error } = await postBulkUploadFormData(
      { directoryPath },
      { files }
    );
    if (!error) {
      doPopulateDirectoryItems();
    }
  };

  const doPostAudioFiles = async files => {
    const { error } = await postAudioFilesFormData(
      { directoryPath },
      { files }
    );
    if (!error) {
      doPopulateDirectoryItems();
    }
  };

  const doPatchItem = async (id, updatedFields = {}) => {
    const item = directoryItems.find(item => item?.id === id);
    let error = null;

    if (item?.type === "WORD_DOC" || item?.type === "CHAT_DOC") {
      const { data: docContent } = await getWordDoc(item?.id);
      ({ error } = await patchWordDoc(
        item?.id,
        {},
        { ...docContent, ...updatedFields }
      ));
    }
    if (item?.type === "EXCEL_MODEL") {
      ({ error } = await patchExcelModelById(
        item?.id,
        {},
        // {
        //   startRow: 0,
        //   endRow: 10,
        //   startCol: 0,
        //   endCol: 10,
        // },
        updatedFields
      ));
    }
    if (item?.type === "SLIDE_DOC") {
      ({ error } = await patchSlideDoc(item?.id, {}, updatedFields));
    }
    if (item?.type === "FILE") {
      ({ error } = await patchFileById(item?.id, updatedFields));
    }
    if (item?.type === "DIRECTORY") {
      ({ error } = await postRenameDirectory(
        {},
        {
          newDirectoryPath: updatedFields?.path,
          oldDirectoryPath: item?.path,
          userId: getLoggedInUserName(),
        }
      ));
    }

    if (!error) {
      updateItem(id, updatedFields);
    }
    setError(error);
  };

  const updateItem = (id, updatedFields) => {
    setDirectoryItems(prev =>
      prev.map(item => {
        if (item?.id === id) {
          return {
            ...item,
            ...updatedFields,
          };
        }
        return item;
      })
    );
    doPopulateDirectoryItems();
  };

  const actionNameToComponent = {
    new_excel: (
      <ActionItem
        onClick={async () => {
          await doCreateExcelModel(directoryPath);
          doPopulateDirectoryItems();
        }}
      >
        <ExcelIcon />
        New excel
      </ActionItem>
    ),
    upload_excel: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          accept=".xlsx"
          onChange={async e => {
            const filesArray = [...e.target.files];
            await doUploadExcelFile(directoryPath, filesArray?.[0]);
            doPopulateDirectoryItems();
          }}
        />
        <ExcelIcon />
        Upload excel
      </ActionItem>
    ),
    new_ppt: (
      <ActionItem
        onClick={async () => {
          await doCreateSlideDoc(directoryPath);
          doPopulateDirectoryItems();
        }}
      >
        <SlidesIcon style={{ fill: "#d4aa00" }} />
        New presentation
      </ActionItem>
    ),
    upload_ppt: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          accept=".pptx,.ppt"
          onChange={async e => {
            const filesArray = [...e.target.files];
            await doUploadSlideDoc(directoryPath, filesArray?.[0]);
            doPopulateDirectoryItems();
          }}
        />
        <SlidesIcon style={{ fill: "#d4aa00" }} />
        Upload presentation
      </ActionItem>
    ),
    new_chat: (
      <ActionItem onClick={() => doCreateChatDoc(directoryPath)}>
        <StyledActionChatIcon />
        New chat
      </ActionItem>
    ),
    new_word_doc: (
      // <ActionItem onClick={() => doCreateWordDoc(directoryPath)}>
      //   <StyledActionWordIcon />
      //   New word doc
      // </ActionItem>
      <CreateWordModalTrigger
        directoryPath={directoryPath}
        onDocCreated={doPopulateDirectoryItems}
        trigger={
          <ActionItem isActive>
            <WordIcon />
            New word doc
          </ActionItem>
        }
      />
    ),
    upload_word_doc: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          accept=".docx"
          onChange={async e => {
            await doUploadWordDoc(directoryPath, e);
            doPopulateDirectoryItems();
          }}
        />
        <StyledActionWordIcon />
        Upload doc
      </ActionItem>
    ),
    upload_files: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          multiple
          accept=".pdf"
          onChange={async e => {
            const filesArray = [...e.target.files];
            await doPostFiles(filesArray);
            doPopulateDirectoryItems();
          }}
        />
        <StyledActionPdfIcon />
        Upload files
      </ActionItem>
    ),
    ingest_webpage: (
      <IngestWebpageModal
        trigger={
          <ActionItem>
            <StyledActionWebIcon />
            Add webpage
          </ActionItem>
        }
      />
    ),
    upload_audio: (
      <ActionItem>
        <NewItemFileInput
          type="file"
          multiple
          accept=".wav"
          onChange={e => {
            const filesArray = [...e.target.files];
            doPostAudioFiles(filesArray);
          }}
        />
        <StyledActionAudioIcon style={{ height: "14px", width: "14px" }} />
        Upload audio
      </ActionItem>
    ),
    send_email: (
      <SendEmailModal
        trigger={
          <ActionItem>
            <StyledActionEmailIcon />
            Send email
          </ActionItem>
        }
      />
    ),
  };

  const headerTipContent = (
    <Actions>
      {folderActions?.map(action => actionNameToComponent[action])}
      {!isNewFolderActionHidden && (
        <ActionItem onClick={() => doCreateNewFolder(directoryPath)}>
          <FolderPlusIcon />
          New folder
        </ActionItem>
      )}
      {!isDeleteActionHidden && (
        <>
          <ActionItem onClick={() => setIsEditingDirectoryName(true)}>
            <RenameIcon />
            Rename
          </ActionItem>
          <ActionItem
            onClick={() =>
              doDeleteItem({
                type: "DIRECTORY",
                path: directoryPath,
              })
            }
          >
            <BinIcon />
            Delete
          </ActionItem>
          <ShareDirectoryItemModalTrigger
            directoryItem={rootItem}
            trigger={
              <ActionItem>
                <ShareIcon />
                Share
              </ActionItem>
            }
          />
        </>
      )}
    </Actions>
  );

  const dirDisplayName = last(directoryPath.split("/"));
  let dirNameHeader = (
    <DirNameHeader
      onClick={e => setIsOpen(prev => !prev)}
      isSticky={isHeaderSticky}
      onMouseEnter={() => setIsShowingFolderActions(true)}
      onMouseLeave={() => setIsShowingFolderActions(false)}
    >
      {!isIconDisabled && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            marginLeft: 8 + indentPx - INDENT_PX,
          }}
        >
          <StyledChevronDownIcon
            style={{
              transform: `rotate(${isOpen ? "0deg" : "-90deg"})`,
              transition: "transform 0.1s",
            }}
          />
          <StyledFolderIcon />
        </div>
      )}
      {isEditingDirectoryName ? (
        <StyledDirInputWithStateNew
          autoFocus
          initialValue={dirDisplayName}
          onBlur={e => {
            setIsEditingDirectoryName(false);
            const newPath = [
              ...directoryPath.split("/").slice(0, -1),
              e.target.value,
            ].join("/");
            onApplyNewPath(newPath);
          }}
        />
      ) : (
        <DirItemName>{dirDisplayName}</DirItemName>
      )}
      <StyledTooltipClick
        popOverId={directoryPath}
        tipContent={headerTipContent}
      >
        <ActionsTrigger
          style={{
            opacity: isShowingFolderActions || isHeaderSticky ? 1 : 0,
          }}
        >
          {isHeaderSticky ? <StyledPlusIcon /> : <ThreeDots />}
        </ActionsTrigger>
      </StyledTooltipClick>
    </DirNameHeader>
  );

  if (renderHeaderComponent && typeof renderHeaderComponent === "function") {
    dirNameHeader = renderHeaderComponent({ tipContent: headerTipContent });
  }

  const onDragStart = (e, item) => {
    e.stopPropagation();
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const onDrop = async e => {
    e.preventDefault();
    if (isOpen) {
      setIsGreyedOut(true);
    }
    let droppedItem = null;
    try {
      droppedItem = JSON.parse(e.dataTransfer.getData("text/plain"));
    } catch (e) {
      setIsDraggingOver(false);
      return;
    }
    if (droppedItem?.path === directoryPath) {
      setIsDraggingOver(false);
      return;
    }
    await postMoveItem(
      {},
      {
        id: droppedItem?.id,
        itemType: droppedItem?.type,
        newDirectoryPath: directoryPath,
        createIfNotExists: false,
      }
    );

    searchParams.set("refreshFiles", "true");
    navigate(`?${searchParams.toString()}`);

    setIsDraggingOver(false);
  };

  const dirNameHeaderDropable = (
    <DirNameDropZone
      isDraggingOver={isDraggingOver}
      onDragOver={e => {
        setIsDraggingOver(true);
        e.preventDefault();
      }}
      onDragLeave={e => {
        setIsDraggingOver(false);
        e.preventDefault();
      }}
      onDrop={onDrop}
    >
      {dirNameHeader}
    </DirNameDropZone>
  );

  if (isLoadingItems && directoryItems?.length === 0) {
    return (
      <Container>
        {dirNameHeaderDropable}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem>
            <div />
            <CircularProgress
              size={16}
              thickness={6}
              style={{
                color: "#0191ff",
              }}
            />
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  if (!isLoadingItems && directoryItems?.length === 0) {
    return (
      <Container>
        {dirNameHeaderDropable}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem style={{ marginLeft: indentPx }}>
            <div style={{ width: "0px" }} />
            <div style={{ opacity: 0.5, color: "white" }}>No items</div>
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  const filterTypes = sidebar?.filterTypes || "";

  const excelsCount = directoryItems?.filter(
    item => item?.type === "EXCEL_MODEL"
  ).length;
  const wordDocsCount = directoryItems?.filter(
    item => item?.type === "WORD_DOC"
  ).length;
  const slideDocsCount = directoryItems?.filter(
    item => item?.type === "SLIDE_DOC"
  ).length;

  let itemsCountStr = "";
  if (excelsCount) {
    itemsCountStr += `${excelsCount} excels`;
  }
  if (wordDocsCount) {
    itemsCountStr += `${itemsCountStr ? ", " : ""}${wordDocsCount} docs`;
  }
  if (slideDocsCount) {
    itemsCountStr += `${itemsCountStr ? ", " : ""}${slideDocsCount} ppts`;
  }

  return (
    <Container style={{ opacity: isGreyedOut ? 0.5 : 1 }}>
      {dirNameHeaderDropable}
      <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
        {directoryItems
          ?.filter(item => {
            if (!filterTypes?.length) {
              return !item?.isChat;
            }

            if (filterTypes?.includes("CHAT_DOC")) {
              return item?.isChat || item?.type === "DIRECTORY";
            }

            return (
              filterTypes?.includes(item?.type) || item?.type === "DIRECTORY"
            );
          })
          .filter(item => {
            if (!sidebar?.filterText) {
              return true;
            }

            const doesNameMatch = item?.[ITEM_TYPE_TO_NAME_KEY[item?.type]]
              ?.toLowerCase()
              ?.includes(sidebar?.filterText?.toLowerCase());

            const doesPathMatch = item?.directoryPath
              ?.toLowerCase()
              ?.includes(sidebar?.filterText?.toLowerCase());

            return doesNameMatch || doesPathMatch;
          })
          .map(item => {
            if (item?.type === "DIRECTORY") {
              return (
                <FolderItem
                  key={item?.id}
                  draggable
                  onDragStart={e => onDragStart(e, item)}
                >
                  <DirectoryItemsList
                    parentNewItemIds={newItemIds}
                    directoryPath={item?.path}
                    onRemoveSelf={() => doPopulateDirectoryItems()}
                    indentPx={indentPx + INDENT_PX}
                    folderActions={folderActions}
                    rootItem={item}
                    onApplyNewPath={newPath => {
                      doPatchItem(item?.id, { path: newPath });
                    }}
                  />
                </FolderItem>
              );
            }

            let itemQueryStr = "";
            if (directoryPath?.includes("/source-files")) {
              itemQueryStr = `?inSourceFiles=true`;
            }

            const nameKey = ITEM_TYPE_TO_NAME_KEY[item?.type];
            return (
              <DirectoryItem
                draggable
                onDragStart={e => onDragStart(e, item)}
                isDisabled={
                  itemIdBeingDeleted === item?.id ||
                  item?.status === "Processing"
                }
                key={item?.id}
                target="_blank"
                style={{ marginLeft: 11 + indentPx }}
                // style={{ marginLeft: indentPx }}
              >
                <div>{getItemIcon(item) || <div />}</div>
                {editingItemId === item?.id ? (
                  <StyledInputWithStateNew
                    autoFocus
                    initialValue={item?.[nameKey]}
                    onBlur={e => {
                      setEditingItemId(null);
                      doPatchItem(item?.id, { [nameKey]: e.target.value });
                    }}
                    onKeyDown={e => {
                      if (e.key === "Escape") {
                        setEditingItemId(null);
                      }
                      if (e.key === "Enter") {
                        setEditingItemId(null);
                        doPatchItem(item?.id, { [nameKey]: e.target.value });
                      }
                    }}
                  />
                ) : (
                  <ItemName
                    href={`${getItemUrl(item)}${itemQueryStr}`}
                    target="_blank"
                  >
                    {formatItemName(item, item?.[nameKey])}
                  </ItemName>
                )}
                <StyledTooltipClick
                  popOverId={item?.id}
                  tipContent={
                    <Actions>
                      {item?.type !== "EXCEL_MODEL" && (
                        <ActionItem onClick={() => setEditingItemId(item?.id)}>
                          <RenameIcon />
                          Rename
                        </ActionItem>
                      )}
                      <ActionItem onClick={() => doDeleteItem(item)}>
                        <BinIcon />
                        Delete
                      </ActionItem>

                      <ShareDirectoryItemModalTrigger
                        directoryItem={item}
                        trigger={
                          <ActionItem>
                            <ShareIcon />
                            Share
                          </ActionItem>
                        }
                      />
                      <ActionItem onClick={() => downloadItem(item)}>
                        <DownloadIcon style={{ height: "12px" }} /> Download
                      </ActionItem>
                    </Actions>
                  }
                >
                  <ActionsTrigger>
                    <ThreeDots />
                  </ActionsTrigger>
                </StyledTooltipClick>
              </DirectoryItem>
            );
          })}
        {itemsCountStr &&
          directoryPath !== "/working-files" &&
          directoryPath !== "/source-files" && (
            <ItemsCount>({itemsCountStr})</ItemsCount>
          )}
        {itemsRes?.hasMore && (
          <HasMoreBtn onClick={() => setPageSize(prev => prev + 30)}>
            Load more
            {isLoadingItems && <CircularProgress size={16} thickness={8} />}
          </HasMoreBtn>
        )}
        <ErrorMessageModal
          message={error?.message}
          onDismiss={() => setError(null)}
        />
      </ItemsContainer>
    </Container>
  );
};

export default DirectoryItemsList;
