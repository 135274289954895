import { postPipelineConfigsExtraction } from "api/backend/fileSystemEndpoints";
import { CodingIcon } from "components/IconsNew";
import RecordFieldInput from "components/RecordFieldInput";
import RecordFieldInputTable from "components/RecordFieldInputTable";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledTriggerIcon = styled(CodingIcon)`
  fill: grey;
  cursor: pointer;
  height: 18px;
  :hover {
    opacity: 0.8;
  }
`;

const ModalContent = styled.div`
  width: 600px;
  height: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  white-space: pre-wrap;

  display: grid;
  gap: 10px;
  align-content: start;
  grid-template-rows: 40px 1fr;
`;

const ErrorMsg = styled.div`
  color: salmon;
`;

const Title = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
`;

const Column = styled.div`
  display: grid;
  grid-template-rows: 40px 1fr 40px;
  padding-left: 10px;
  align-items: start;
`;

const LeftColumn = styled(Column)`
  border-right: 1px solid #c0c0c0;
  padding-left: 0;
  padding-right: 10px;
`;

const Field = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  ${props => props?.disabled && "opacity: 0.5; pointer-events: none;"}
`;

const BottomItems = styled.div`
  display: grid;
  justify-content: end;
`;

const FieldEditor = ({
  column,
  field,
  onChangeRecordInput = (newValue, dbName) => {},
  isEditMode = false,
  disabled = false,
}) => {
  return (
    <Field
      disabled={disabled}
      style={{
        gridColumn: column?.type === "TABLE" ? "span 2" : "auto",
      }}
    >
      {/* <FieldLabel>{column.displayName}</FieldLabel> */}
      {column?.type === "TABLE" && (
        <RecordFieldInputTable
          column={column}
          tableColumns={column?.tableColumns}
          value={field?.value}
          isEditMode={isEditMode}
          onChangeValue={newValue =>
            onChangeRecordInput(newValue, column.dbName)
          }
          // onChangePlotState={fieldPlotState =>
          //   onChangePlotState(column?.name, fieldPlotState)
          // }
        />
      )}
      {column?.type !== "TABLE" && (
        <RecordFieldInput
          isEditMode={isEditMode}
          column={column}
          value={field?.value || ""}
          onChange={e => onChangeRecordInput(e.target.value, column.dbName)}
          rawValue={field?.rawValue || ""}
        />
      )}
    </Field>
  );
};

const GenerateFieldValueModal = ({
  className = "",
  targetColumnSpec = {},
  record = {},
  onClickApply = newField => {},
}) => {
  const [newField, setNewField] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNewField(null);
    setError(null);
    setIsGenerating(false);
  }, [isOpen]);

  const onClickGenerate = async () => {
    setNewField(null);
    setError(null);
    setIsGenerating(true);

    const { data, error } = await postPipelineConfigsExtraction(
      {},
      {
        targetColumnSpec,
        record,
      }
    );
    if (targetColumnSpec?.type === "TABLE" && !Array?.isArray(data?.value)) {
      setIsGenerating(false);
      setError(data);
      return;
    }

    setNewField(data);
    setError(error);
    setIsGenerating(false);
  };

  return (
    <>
      <StyledTriggerIcon
        onClick={() => setIsOpen(true)}
        className={className}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title style={{ fontSize: 20 }}>
            Generate "{targetColumnSpec?.displayName}"
          </Title>
          <TwoColumns>
            <LeftColumn>
              <Title>Current value</Title>
              <FieldEditor
                column={targetColumnSpec}
                field={record?.fields?.[targetColumnSpec?.dbName]}
              />
            </LeftColumn>
            <Column>
              <Title>
                New value{" "}
                <ButtonWord
                  disabled={isGenerating}
                  onClick={onClickGenerate}
                  isPrimary
                >
                  Generate
                </ButtonWord>
              </Title>
              <FieldEditor
                disabled={!newField?.value}
                column={targetColumnSpec}
                field={newField}
                isEditMode
                onChangeRecordInput={(newValue, dbName) => {
                  setNewField({
                    ...newField,
                    value: newValue,
                  });
                }}
              />
            </Column>
          </TwoColumns>
          {error && <ErrorMsg>{JSON.stringify(error)}</ErrorMsg>}
          <BottomItems>
            <ButtonWord
              disabled={!newField?.value}
              isPrimary
              onClick={() => {
                onClickApply(newField);
                setIsOpen(false);
              }}
            >
              Apply
            </ButtonWord>
          </BottomItems>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateFieldValueModal;
